export class Usuario{
    cliente_tipoidentificacion ?:string| undefined;
    cliente_tipopersona?: string;
    cliente_identificacion?: string;
    cliente_apellido_1?:string;
    cliente_apellido_2?: string;
    cliente_nombre_1?: string;
    cliente_nombre_2?: string;
    cliente_departamento?: string;
    cliente_ciudad?: string;
    usuario_email?: string;
    cliente_direccion?: string;
    cliente_telefono?: string;
    cliente_celular?: string;
    cliente_fnacimiento?: string;
    usuario_password?:string
    login?:string;
    password?:string;
}


