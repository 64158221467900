import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-compra',
  template:`<div class="progress h-25 mt-3 d-flex w-100 div-scroll" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
              <div [ngClass]="divActivo=='div-pb-1'?'div-pb-activo': 'div-pb-inactivo'" class="progress-bar py-2" [ngStyle]="carrito"><i class="bi bi-1-circle fs-4"></i>Verifica tu carrito de compras</div>
              <div [ngClass]="divActivo=='div-pb-2'?'div-pb-activo': 'div-pb-inactivo'" class="progress-bar py-2" [ngStyle]="datosPersonales"><i class="bi bi-2-circle fs-4"></i>Diligencia datos Personales</div>
              <div [ngClass]="divActivo=='div-pb-3'?'div-pb-activo': 'div-pb-inactivo'" class="progress-bar py-2" [ngStyle]="pagar"><i class="bi bi-3-circle fs-4"></i>Confirma y paga tu pedido</div>
            </div>`,
  styles:[`
    .div-scroll{ @media (max-width:  1400px) {
      overflow: auto !important;
    }}
   
    .div-pb-activo{ @media (max-width:  575px) {
      width: 100% !important;
    }}
    .div-pb-inactivo{ @media (max-width:  575px) {
      display: none;
    }}
    
  `]
      
})
export class ProgressCompraComponent {
  @Input() divActivo = 'div-pb-1';
  @Input() estado = 0;
  @Input() carrito: { [key: string]: string } = {'width': '33%', 'color': 'black', 'background-color':'#B3B2AE','min-width':'200px'};
  @Input() datosPersonales: { [key: string]: string } = {'width': '33%', 'color':'black', 'background-color':'#F8F9FA', 'min-width':'200px'};
  @Input() pagar: { [key: string]: string } = {'width': '34%', 'color':'black', 'background-color':'#F8F9FA', 'min-width':'200px'};
}
