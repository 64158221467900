import { Component, Input, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payu',
  templateUrl: './payu.component.html',
})
export class PayuComponent implements OnInit {


  _deshabilitar:any; 
  _disabled:any;
  habilitar:any;
  buyerEmail = '';

  merchantId  = environment.MERCHANTID;
  accountId   = environment.ACCOUNTID;
  currency    = environment.CURRENCY;
  test        = environment.TEST;
  responseUrl = environment.RESPONSEURL;
  confirmationUrl = environment.CONFIRMATIONURL;


  @Input() amount = 0;
  @Input() tax: any ;
  @Input() taxReturnBase = 0;
  @Input() signature = '';
  @Input() referenceCode = '';
  @Input() codigo_cliente_tienda = '';
  @Input() referencia_producto = '';
  @Input() set disabled(value:any){
    this._disabled = value;
    this.habilitar = this.getHabilitar;
  };

  @Input() set deshabilitar(value:any){
      this._deshabilitar = value;
      this.habilitar = this.getHabilitar;
  };

  ngOnInit(): void {
    this.getUsuario();  
  }
  
 
  get getHabilitar(){
    //Habilitar pagar
    if ( this._disabled && !(this._deshabilitar) ){
      return false; 
    }else{
      return true;
    }
  }

  getUsuario(){
    const datosUsuario = JSON.parse(localStorage.getItem('authToken')!);
    this.buyerEmail = datosUsuario.data.usuario_email;
  }
}