<div class="mb-2 pb-2">

  <div class="d-flex justify-content-center align-items-center flex-wrap column-gap-5 row-gap-3">
   
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h4>KGM COLOMBIA</h4>
      <img class="sistemaverde" src="../../../../assets/img/sistema_verde@100x.webp" alt="sistema_verde">
    </div>
   
    <div class="d-flex flex-column justify-content-center align-items-center col-sm-12 col-md-4">
      <h4>PRIVACIDAD</h4>
      <ul class="">
        <li>
          <a href="https://cdnprocessoft.s3.amazonaws.com/SITIOS/xGJ4XukfAJKAPdHEBn0PZd87ZD0tRng595yu2SHQgDM/T%C3%89RMINOS%20Y%20CONDICIONES%20DE%20USO%20TIENDA%20VIRTUAL.pdf"
            target="_blank"> Condiciones de tienda virtual</a>
        </li>
        <li>
          <a href="https://cdnprocessoft.s3.amazonaws.com/SITIOS/xGJ4XukfAJKAPdHEBn0PZd87ZD0tRng595yu2SHQgDM/files/AVISO%20DE%20PRIVACIDAD.pdf"
            target="_blank">Aviso de privacidad </a>
        </li>
        <li>
          <a href="https://cdnprocessoft.s3.amazonaws.com/SITIOS/xGJ4XukfAJKAPdHEBn0PZd87ZD0tRng595yu2SHQgDM/files/PTDP%20-%20SSANGYONG%20MOTOR%20COLOMBIA.pdf"
            target="_blank">Política de tratamientos datos </a>
        </li>
        <li>
          <a href="https://www.kgm.com.co/wp-content/uploads/2024/04/Politicas-de-cookies.pdf" target="_blank"> Política
            de cookies </a>
        </li>
        <li>
          <a href="https://www.kgm.com.co/wp-content/uploads/2024/04/Ley-de-Reestructuracion.pdf" target="_blank"> Ley
            de restructuración </a>
        </li>
      </ul>
    </div>

    <div class="d-flex flex-column justify-content-start align-items-center">
      <h4>KGM SOCIAL</h4>
      <div class="d-flex justify-content-between">
        <a href="https://www.facebook.com/kgmcolombia/"> <img class="rs mx-2 facebook" src="../../../../assets/img/facebook-negro.png" alt="facebook"></a>
        <a href="https://www.instagram.com/ssangyongcolombia/"><img class="rs mx-2 instagram" src="../../../../assets/img/instagram-negro.png" alt="instagram"></a>
        <a href="https://www.youtube.com/@kgmcolombia"> <img class="rs mx-2 youtube" src="../../../../assets/img/youtube-negro.png"  alt="youtube"></a>
      </div>
    </div>
  </div>


</div>