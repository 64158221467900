import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})



export class UtilService {

  API_BACKEND = environment.API_BACKEND; 

  constructor(private http: HttpClient) { }

  showAlert(text: string, title: title = 'Error!', icon:icon = 'error', confirmButtonText: string='Cerrar') {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    });
  }

  validarEmail(control: FormControl){
    let isValid = control.value;
    let regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    let res = regex.test(control.value);

    if (res) {
      return null;
    }
    return { validaEmail: isValid };
  }


  validaCelular(control: FormControl) {

    let isValid = control.value;
    let regex = /^3[0-9]{9}$/;
    let res = regex.test(control.value);

    if (res) {
      return null;
    }
    return { validCel: isValid };
  };

  //valida que contenga 8 caracteres una mayuscula, un numero y caracter especial
  validaPassword(control: FormControl){
    let isValid = control.value;
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let res = regex.test(control.value);

    if (res) {
      return null;
    }
    return { validCel: isValid };
  }

  crearErrorLog(payload: any){
    const URL = `${this.API_BACKEND}/logs/log`;
    return this.http.post(URL, payload);
  }


}

type title = 'Exito!'|'Advertencia!'|'Error!'|'Info!'|'Pregunta!'; 
type icon = 'success'|'error'|'warning'|'info'|'question'; 
