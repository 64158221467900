export const environment = {
   production: false,
   APIPROCESSOTF :  '',
   //local
   //API_BACKEND: 'http://192.168.18.217:3000/api',
   //test
   API_BACKEND: 'https://pptienda.kgm.com.co:8080/api',


   //URL
   URL_VIDEO_COMPRAR:'https://www.youtube.com/embed/mHNYlyFP3Vk',
   URL_NUESTROS_VEHICULOS:'https://www.kgm.com.co/',
   URL_COORDINADORA:'https://coordinadora.com/',
   URL_WHATSAPP:'https://api.whatsapp.com/send/?phone=573234898249',
   
   //API SIISA
   SIISA_KEY : 'PCHOyEfKmbHANe61cYo48nEcNf7ubMhFRH1ZyQLIXfTqr9t9788vRV62SY9jwLalC4w0hcxIGIkeb0LCkGw61tZZnpP3RUYYj06ZOisfatLc4aTWhZcI1QujAzHjdY3fck0WcRx5qCLrILxWvrCgWHWC6VTL5nip51Y4IgepO0acdtfKt78e3NZQEEtN276xo98TlMmJMCLHwfRlIhOHzjQR5EdKOdSs3bg31YP8reauVSMXZG75WWjRV6OSJciAFvIsPGJvtnv5lhw8JlYZ1ZMdrAjiYAjcN02GhjcfFfZ2Vtb3GTDSJczNiC1EnTD1Ypjds1qXrhtw6TiTd5nzEEeeCvbNksI9VuLTiFesBmRAgVMyRmdr3VPyKVFSFyEpVbqPle99iPKCtEIjQ1WuK9RN4IfowwnHWgcN2rNtqbw9qKU2nwT2Vul3d9kAG1kQapOkBfCDpqQv7i6jhObqZyLLeHkHMfkZLcebfh37b8oj5B49MZ6tO9IFS0i4yiYgRvJy9BhpkwMj2BId7kdBR7CgEclWnXTrgKvo8ZZhjhXg0r0XjlDoq16oAqfxNXOEb9EI8VgXUcZozng470xZB3bmyA9ci9FMUZw6NPHTPQcvqW6iY9AbC42qYu35mn69cDmmanRjPQzVHGHUt3FlLSmrbxpRRaf3lcmUGEIh71KCLz7UWE2JE0BPf8F16kSWge6GUhY6FhZKdEch3x4gQsFFBahHnYI8oC1m0nzok1F3q46cbunuPgFVC1A3a8POBzL1izg6ALTBEDq2lZQgQDwX50VDuEjVl9sNwhYywWZmLljA0w9Hr3cPeeI7BgibzJ3yTTBlKjubp7BctY9mlmUI7a1xQc6BzFtDy5ofQYAVe5pgAoiDxLmZXFa9evTALihRE0uELfEWnkhufSVgpbLQwnpOVdjpAhbm4MfPSCiefJRW8tLhsOZWfduy0ZvWuaetWSRGgcS2zVceKEWIb5YRteQQ6UEN2CVPFAIPF7pO2LY8ogesUt6x9yCMQ2Bn',
   API_SIISA_TV  : 'https://siisa.processoft.com.co/WS/PROCESSOFT/tienda_virtual_desa.php',
   API_SIISA_TVC : 'https://siisa.processoft.com.co/WS/PROCESSOFT/tienda_virtual_c_desa.php',
   API_SIISA_CL :  'https://siisa.processoft.com.co/WS/PROCESSOFT/tienda_virtual_cl_desa.php',
   API_SIISA_PD :  'https://siisa.processoft.com.co/WS/PROCESSOFT/tienda_virtual_pd_desa.php',


   //API AGENDAMIENTO
   API_INDICAR: 'https://www.indicar.com.co/server_siisa/servicios/service_desa.php',

   TYC:'https://cdnprocessoft.s3.amazonaws.com/SITIOS/xGJ4XukfAJKAPdHEBn0PZd87ZD0tRng595yu2SHQgDM/T%C3%89RMINOS%20Y%20CONDICIONES%20DE%20USO%20TIENDA%20VIRTUAL.pdf',
     
   //PAYU
   APIKEY:'4Vj8eK4rloUd272L48hsrarnUA',
   MERCHANTID:'508029',
   ACCOUNTID:'512321',
   CURRENCY:'COP',
   TEST:'1',
   //LOCAL
   //RESPONSEURL:'http://localhost:4200/confirmar-pago',
   //TEST
   RESPONSEURL:'https://pptienda.kgm.com.co/confirmar-pago',
   CONFIRMATIONURL:'https://pptienda.kgm.com.co:8080/api/referencia/confirmacion-pago',
      
};