<div class="row d-flex justify-content-center mb-5">
    <h3 class="text-center title-products">{{titulo}}</h3>
  
    <div class="card" style="width: 20rem; margin:5px" *ngFor="let repuesto of dataRepuestos">
      <img [src]="repuesto.url_imagen || '/assets/img/marcaaguanewsize.webp'" (error)="repuesto.url_imagen = '/assets/img/marcaaguanewsize.webp'" class="card-img-top img-accesorio" (click)="onHandleVer(repuesto.referencia_codigo)"
        alt="..." (click)="onHandleVer(repuesto.id)">
      <div class="card-body">
        <h6 class="card-title text-center" style="font-size: 11px !important;"> {{ repuesto.referencia_nombre }} </h6>
        <p class="card-text text-center"><strong> $ {{ repuesto.referencia_preciovigente| number:'1.0-0' }} IVA
            incluido</strong></p>
        <button class="btn btn-promocion" (click)="onHandleAdd(repuesto)" this.repuestos [disabled]="isDisabled">
          <img src="../../../assets/img/carritodecompras.webp" alt="logocarrocompra" height="18px">AÑADIR
        </button>
      </div>
    </div>

  </div>
