<div *ngIf="showLoading" class="d-flex justify-content-center mt-2" >
    <app-loading srcImg="../../../assets/img/alaslogo.webp" />
</div>

<div class="container mt-5" *ngIf="listaRepuestos.length > 0; else carrioVacio">

    <div>
        <app-progress-compra />
    </div>


    <h2>Carrito de Compras</h2>
   
    <div class="table-responsive">

        <app-tabla-pedido 
            [data] = "listaRepuestos"
            (HandlerRemoveItem)="removeItem($event)"
            (HandlerAddItem)="addItem($event)"
            (HandlerEliminarItem)="eliminarItem($event)"
           
        />

        <div>
            <app-tabla-resumen-iva 
                [importe]="getImporte() | currency"
                [totalPrice]="getTotalPrice() | currency"
                [IVA]="getIVA() | currency"
            />
        </div>
    </div>

    <div class="d-flex justify-content-center mb-5 text-color">
        <h5> <p class="text-center text-success">{{msg_compra_minima}}</p></h5>
    </div>
    
    <div class="text-right d-flex justify-content-center mb-3">
        <button  class="btn btn-hover btn-atras mx-1" (click)="atras()" >
            Atrás
        </button> 
        <button  class="btn btn-hover mx-1" (click)="comprar()"  style="background-color:#373151; color: white; margin-top: 20px; margin-bottom:20px; width: 220px; justify-content: center; align-items: center;">
            Siguiente
        </button> 
    </div>

    <div class="d-flex justify-content-center mb-5">
        <p class="text-center">
            {{frase}}
        </p>
    </div>

</div>

<ng-template #carrioVacio>
    <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-6 text-center">
         
             <img src="../../../assets/img/carritodecompras.png" alt="carrito compras">
             <h5 class="mt-3">Tu carrito está vacío</h5> 
             <a class="btn btn-hover" style="background-color:#3F3953; color: white; margin-left: 20px; margin-top: 20px; margin-bottom:20px; width: 220px; justify-content: center; align-items: center;"
                (click)="irBuscar()"
             >Volver a la busqueda</a>
            </div>
        </div>
      </div>
</ng-template>