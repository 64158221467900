import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  styleUrls: ['./loading.component.css'],
  template:`<div class="d-flex justify-content-center align-items-center vh-100">
              <div class="spinner-wrapper">
                <div class="spinner-border" role="status"></div>
                <img [src]="srcImg" alt="Imagen Estática" class="spinner-image">
              </div>
            </div>`
})
export class LoadingComponent {

  @Input() srcImg = '';

}