<div>
    <form #payuForm method="post" action="{{URL_PAYU}}" ngNoForm>
        <input name="merchantId"      type="hidden"  value="{{merchantId}}"   >
        <input name="accountId"       type="hidden"  value="{{accountId}}" >
        <input name="description"     type="hidden"  value="Compra repuestos SsangYong / KGM"  >
        <input name="referenceCode"   type="hidden"  value="{{referenceCode}}" >
        <input name="amount"          type="hidden"  value="{{amount}}"   >
        <input name="tax"             type="hidden"  value="{{tax}}">
        <input name="taxReturnBase"   type="hidden"  value="{{taxReturnBase}}" >
        <input name="currency"        type="hidden"  value="{{currency}}" >
        <input name="signature"       type="hidden"  value="{{signature}}"  >
        <input name="test"            type="hidden"  value="{{test}}" >
        <input name="extra1"          type="hidden"  value="{{codigo_cliente_tienda}}" >
        <input name="extra2"          type="hidden"  value="{{referencia_producto}}" >
        <input name="buyerEmail"      type="hidden"  value="{{buyerEmail}}" >
        <input name="responseUrl"     type="hidden"  value="{{responseUrl}}" >
        <input name="confirmationUrl" type="hidden"  value="{{confirmationUrl}}" >
    </form>
    <button class="btn btn-primary btn-siguiente rounded" [disabled] ="habilitar" (click)="handlerPagar()">
        <span *ngIf="isloading"> <app-spinner color="text-light" /> </span>
        <span *ngIf="!isloading" style="color: white;">Pagar</span>    
    </button>
</div> 

<!-- Modal -->
<div #modalConfirm  class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" style="margin-top: 8rem;">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Desar continuar con el pedido?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <p>{{msgModalBody}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-regreso btn-hover" data-bs-dismiss="modal" (click)="cancelarPedido()">No</button>
            <button type="button" class="btn btn-primary btn-siguiente" (click)="procesarPedido()">Si</button>
        </div>
        </div>
    </div>
</div>