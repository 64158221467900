<form [formGroup]="frmAgendamiento" (ngSubmit)="onSubmit()">
    <div class="d-flex justify-content-center flex-column">
        <div class="mb-3">
        
            <div class="position-relative">
                <input formControlName="nombre" class="form-control mb-3" type="text" placeholder="Nombre completo" style="padding-left: 2rem;">
                <i class="bi bi-person position-absolute" style="left: 10px; top: 50%; transform: translateY(-50%);"></i>
            </div>  
            <div style="margin-top: -16px;"  
                *ngIf="frmAgendamiento.get('nombre')?.invalid && (frmAgendamiento.get('nombre')?.dirty || frmAgendamiento.get('nombre')?.touched)">
                <small class="text-danger">Nombre no válido</small> 
            </div>  
            
            <div class="position-relative">
                <input formControlName="email" class="form-control mb-3" type="text" placeholder="Correo electrónico" style="padding-left: 2rem;">
                <i class="bi bi-envelope position-absolute" style="left: 10px; top: 50%; transform: translateY(-50%);"></i>
                
            </div>  
            <div style="margin-top: -16px;"  
                *ngIf="frmAgendamiento.get('email')?.invalid && (frmAgendamiento.get('email')?.dirty || frmAgendamiento.get('email')?.touched)">
                <small class="text-danger">Correo no válido</small> 
            </div>

            <div class="position-relative">
                <input formControlName="celular" class="form-control mb-3" type="text" placeholder="Celular" style="padding-left: 2rem;">
                <i class="bi bi-phone position-absolute" style="left: 10px; top: 50%; transform: translateY(-50%);"></i>
            </div>
            <div style="margin-top: -16px;"  
                *ngIf="frmAgendamiento.get('celular')?.invalid && (frmAgendamiento.get('celular')?.dirty || frmAgendamiento.get('celular')?.touched)">
                <small class="text-danger">Celular no válido</small> 
            </div>  

            <div class="position-relative">
                <input formControlName="placa" class="form-control mb-3" type="text" placeholder="Placa" style="padding-left: 2rem;">
                <i class="bi bi-card-heading position-absolute" style="left: 10px; top: 50%; transform: translateY(-50%);"></i>
            </div> 
            <div style="margin-top: -16px;"  
                *ngIf="frmAgendamiento.get('placa')?.invalid && (frmAgendamiento.get('placa')?.dirty || frmAgendamiento.get('placa')?.touched)">
                <small class="text-danger">Placa no válida</small> 
            </div>   
            
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary btn-agendamiento" [disabled]=!frmAgendamiento.valid>
                <span *ngIf="loading"> <app-spinner color="text-light" /> </span>
                <span *ngIf="!loading" style="color: white;">Agendar</span>
            </button>
        </div>  
    </div>
</form>



