import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tabla-resumen-iva',
  templateUrl: './tabla-resumen-iva.component.html',
})
export class TablaResumenIvaComponent {

  @Input() importe:any;
  @Input() totalPrice: any;
  @Input() IVA: any;

}
