import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class LineaService {

    private modelos = new BehaviorSubject([]);

    anosModelo: any;
    private apiUrl  = environment.API_SIISA_TV;
    private key     =  environment.SIISA_KEY;
    selectedInfo: any;

    constructor(private httpClient: HttpClient,) {}

    setModelos(modelos: any){
        this.modelos.next(modelos)
    }

    getModelos(){
        return this.modelos.value;
    } 

    getModels() {
        return this.modelos.asObservable();
    }
    
    getAnosModelo() {
        const data = {
            p_access: this.key,
            action: "getModelo",
            tipo: 1,
            marca: "SSY"
        };
        const params = new HttpParams({ fromObject: data });
        return this.httpClient.get<any>(this.apiUrl, { params });
    }

    getFamilia(payload: any) {
        const dataFamilia = {
            p_access: this.key,
            action: "getFamilia",
            tipo: 1,
            marca: "SSY",
            modelo: payload.modelo,
        }
        const params = new HttpParams({ fromObject: dataFamilia });
        return this.httpClient.get<any>(this.apiUrl, { params });
    }

    getcajaCambios(payload: any) {
        const dataCajaCambio = {
            p_access: this.key,
            action: "getCajacambio",
            tipo: 1,
            marca: "SSY",
            modelo: payload.modelo,
            familia: payload.familia,
        }
        const params = new HttpParams({ fromObject: dataCajaCambio });
        return this.httpClient.get<any>(this.apiUrl, { params });
    }

    getCombustible(payload: any){
        const dataCombustible ={
            p_access: this.key,
            action: "getCombustible",
            tipo:1,
            marca:"SSY",
            modelo:  payload.modelo,
            familia: payload.familia,
            caja:    payload.caja     
        }
        const params = new HttpParams({ fromObject: dataCombustible });
        return this.httpClient.get<any>(this.apiUrl, { params });

    }

    getLinea(payload: any){
        const dataLinea= {
            p_access: this.key,
            action: "getLinea",
            tipo:1,
            marca:"SSY",
            modelo:     payload.modelo,
            familia:    payload.familia,
            caja:       payload.caja,
            motor:      payload.motor    
        }
        const params = new HttpParams({ fromObject: dataLinea });
        return this.httpClient.get<any>(this.apiUrl, { params });
    }
}