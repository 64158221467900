import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-recodar-password',
  templateUrl: './recodar-password.component.html',
})
export class RecodarPasswordComponent implements OnDestroy {

  passwordForm: FormGroup;
  isloading = false;
  recordarPassword$: Subscription = new Subscription();

  constructor(private fb: FormBuilder, private utilService: UtilService, private usuarioService: UsuarioService){
    this.passwordForm = this.fb.group({
      email: ['', [this.utilService.validarEmail]]
    });
  }

  resetPassword(){

    const payload = {
      email: this.passwordForm.get('email')!.value
    } 
    this.isloading = true;
    this.recordarPassword$ =this.usuarioService.recordarPassword(payload).subscribe((resp: any)=>{
      this.isloading = false;
      if(resp.state){
        this.utilService.showAlert(resp.message,'Exito!','success');
      }
    }, (error:HttpErrorResponse)=>{
      console.log('error',error);
      this.isloading = false;
      if(typeof(error.error) == 'object'){
        this.utilService.showAlert(error.error.message,'Advertencia!','warning');
      }else{
        this.utilService.showAlert(error.message,'Error!', 'error');
      }
    });
  }

  ngOnDestroy(): void {
    if(this.recordarPassword$){
      this.recordarPassword$.unsubscribe();
    }
  }



}
