import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { environment } from 'src/environments/environment';
import { UtilService } from '../services/util.service';
import { msg } from '../interfaces/mensajes.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  lineas: any[] = [];
  mensaje = msg;

  filtros = [
    {

      indice:0,
      id:"tab1-tab",
      tab : "tab1",
      nombre: 'Búsqueda por placa',
      active: true,
      selected:true
    },
    {
      indice:1,
      id:"tab1-tab",
      tab : "tab2",
      nombre: 'Búsqueda por VIN',
      active: false,
      selected:false
    },
    {
      indice:2,
      id:"tab1-tab",
      tab : "tab3",
      nombre: 'Búsqueda por REF',
      active: false,
      selected:false
    },
    {
      indice:3,
      id:"tab1-tab",
      tab : "tab4",
      nombre: 'Búsqueda por linea',
      active: false,
      selected:false
    },
  ];
 
  coordinadora = environment.URL_COORDINADORA;
  whatsapp = environment.URL_WHATSAPP;

  constructor(
    private busquedaService: BusquedaService,
    private router: Router,
    private utilSrv: UtilService
  ) {}
  
  capturarNumeroLinea(numeroLinea: any){
    this.router.navigate(['/repuestos',numeroLinea]);
  }  

  
  capturarDatos(data: any) {
 
    this.busquedaService.setVin([]);
    this.busquedaService.setNumerolinea('');
    this.busquedaService.setRepuestosOEM([]);
    this.busquedaService.setRepuestosPalabra([]);
   
    if( data.consulta == 1 ){
      this.consultarPorPlaca(data.valor);
    }else if( data.consulta == 2 ){
      this.consultarPorVin(data.valor);
    }else if( data.consulta == 3 ){
      this.consultarPorRef(data.valor);
    }else if( data.consulta == 4 ){
      this.capturarNumeroLinea( data.linea);
    }
  }
  
  
  consultarPorPlaca(placa: string){
    this.lineas = [];
    this.busquedaService.getPlaca(placa).subscribe({
      next: (resp: any)=>{
        if(resp.datos.retorno.length > 0){
          this.lineas.push(resp.datos.retorno[0].linecome_codigo) ;
        }else{
          this.utilSrv.showAlert(this.mensaje.MSG_PLACA_INVALIDA, 'Advertencia!','warning');
          return;
        }
        this.router.navigate(['/repuestos',this.lineas[0]]);
      },
      error: (error: HttpErrorResponse)=>{
        console.log('error',error);
        this.utilSrv.showAlert(error.message, 'Error!', 'error');
      }
    });
  }
  
  consultarPorVin(vin: string){
    this.lineas = [];
    this.busquedaService.getVin(vin).subscribe({
      next: (resp: any)=>{
        if(resp.datos.retorno.length > 0){
          this.lineas.push(resp.datos.retorno[0].linecome_codigo) ;
        }else{
          this.utilSrv.showAlert(this.mensaje.MSG_VIN_INVALIDO, 'Advertencia!','warning');
          return;
        }
        this.router.navigate(['/repuestos',this.lineas[0]]);
      },
      error: (error: HttpErrorResponse)=>{
        console.log('error',error);
        this.utilSrv.showAlert(error.message, 'Error!', 'error');
      }
    });

  }
  
  
  consultarPorRef(ref: string){
   this.busquedaService.getReferencia(ref).subscribe({
      next: (resp: any)=>{
        if (resp.datos.referencia_nombre.length > 0){
          this.router.navigate(['/repuesto', ref]);
        }else{
          this.utilSrv.showAlert(this.mensaje.MSG_VIN_INVALIDO, 'Advertencia!','warning');
          return;
        }
      },
      error: (error: HttpErrorResponse)=>{
        console.log('error',error);
        this.utilSrv.showAlert(error.message, 'Error!', 'error');
      }
    });
  }
  
}