import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tabla-pedido',
  templateUrl: './tabla-pedido.component.html',
  styleUrls: ['./tabla-pedido.component.css']
})
export class TablaPedidoComponent {


  @Input() data:any = [];
  @Input() producto     = true;
  @Input() btnEliminar  = true;
  @Input() btnAddDel    = true;

  @Output() HandlerRemoveItem = new EventEmitter<any>();
  @Output() HandlerAddItem = new EventEmitter<any>();
  @Output() HandlerEliminarItem = new EventEmitter<any>();


  removeItem(item: any){
    this.HandlerRemoveItem.emit(item);
  }

  addItem(item: any){
    this.HandlerAddItem.emit(item);
  }

  eliminarItem(item: any){
    this.HandlerEliminarItem.emit(item);
  }

}