import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-form-agendamiento',
  templateUrl: './form-agendamiento.component.html',
})
export class FormAgendamientoComponent {

  frmAgendamiento: FormGroup;
  @ViewChild(FormGroupDirective) frmAgenDirective!: FormGroupDirective;

  loading = false;

  constructor(private fb: FormBuilder, private utilSrv: UtilService, private busquedadSrv: BusquedaService ){

    this.frmAgendamiento = this.fb.group({

      nombre : ['', [Validators.required, Validators.minLength(6)]],
      email : ['', [Validators.required, this.utilSrv.validarEmail]],
      celular : ['', [Validators.required, this.utilSrv.validaCelular]],
      placa : ['', [Validators.required, Validators.minLength(6)]],

    });

  }



  onSubmit(){

    const payload = {
      nombre: (this.frmAgendamiento.get('nombre')!.value).toUpperCase(),
      email:  this.frmAgendamiento.get('email')!.value,
      celular: this.frmAgendamiento.get('celular')!.value,
      placa:  (this.frmAgendamiento.get('placa')!.value).toUpperCase()
    };

    this.loading = true;
    
    this.busquedadSrv.agendar(payload).subscribe(resp=>{
      this.loading = false;
      
      if(resp.success){
        this.frmAgenDirective.resetForm();
        this.utilSrv.showAlert('Se registró su agendamiento','Exito!','success');
      }else{
        this.utilSrv.showAlert('Se presentaron inconvenientes','Advertencia!','warning');
      }

    }, (error: HttpErrorResponse)=>{
      this.loading = false;
      console.log('error',error);
      this.utilSrv.showAlert(error.message, 'Error!','error');
    });

  }


}