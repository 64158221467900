import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.services';
import { AuthData } from 'src/app/interfaces/auth.interfece';

declare var bootstrap: any;

@Component({
  selector: 'app-datosdeenvio',
  templateUrl: './datosdeenvio.component.html',
  styleUrls: ['./datosdeenvio.component.css']
})
export class DatosdeenvioComponent implements OnInit, OnDestroy {
  datosPersonales: FormGroup;
  validationMessages!: { [key: string]: { [key: string]: string } };
  isloading = false;
  logIn = false; 
  login$: Subscription = new Subscription();



  datosUsuario?: AuthData;

  constructor(private fb: FormBuilder, private utilService: UtilService, private router: Router, private authService: AuthService ) {
    this.datosPersonales = this.fb.group({

      t_Identificacion: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      cliente_identificacion: ["", [Validators.required]],
      cliente_tipopersona: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      prnombre: ["", [Validators.required]],
      sgdonombre: ["", [Validators.required]],
      prapellido: ["", [Validators.required]],
      sgdoapellido: ["", [Validators.required]],
      cliente_departamento: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      cliente_ciudad: ['0', [Validators.required, Validators.pattern(/^(?!0$).+/)]],
      direccion: ['', [Validators.required, Validators.minLength(6)]],
      cliente_telefono: '',
      cliente_celular: ['', [this.utilService.validaCelular]],


    });
    /************ MENSAJES DE VALIDACION ******************/
    this.validationMessages = {
      t_Identificacion: {
        required: 'Tipo de identificación requerida'
      },
      cliente_identificacion: {
        required: 'Identificación requerida'
      },
      prnombre: {
        required: 'El primer nombre es requerido',
      },
      prapellido: {
        required: 'El primer apellido es requerido',
      },
      email: {
        required: 'El correo electrónico es requerido',
        validaEmail: 'El correo no es válido'
      },
      direccion: {
        required: 'La dirección es requerida',
        minlength: 'La dirección debe contener al menos 6 caracteres'
      },
      cliente_celular: {
        validCel: 'El celular no es válido'
      },
      pass: {
        required: 'El password es requerido',
        minlength: 'El nombre debe tener al menos 6 caracteres'
      }

    };
  }

  ngOnInit(): void {
    this.isLogin();
    this.getDatosCliente();
  }


  getUsuario() {
    const usuario = localStorage.getItem('authToken');

    if (!!usuario) {
      var datosCliente = JSON.parse(usuario);
      console.log(datosCliente);
      return JSON.parse(usuario);
    }
    return null;
  }
  registro() {
    const payload = {
      cliente_tipoidentificacion: this.datosPersonales.get('t_Identificacion')!.value,
      cliente_tipopersona: this.datosPersonales.get('cliente_tipopersona')!.value,
      cliente_identificacion: this.datosPersonales.get('cliente_identificacion')!.value,
      cliente_apellido_1: this.datosPersonales.get('prapellido')!.value,
      cliente_apellido_2: this.datosPersonales.get('sgdonombre')?.value,
      cliente_nombre_1: this.datosPersonales.get('prnombre')!.value,
      cliente_nombre_2: this.datosPersonales.get('sgdonombre')?.value,
      cliente_departamento: this.datosPersonales.get('cliente_departamento')!.value,
      cliente_ciudad: this.datosPersonales.get('cliente_ciudad')!.value,
      // usuario_email: this.datosPersonales.get('email')!.value,
      cliente_direccion: this.datosPersonales.get('direccion')!.value,
      cliente_telefono: this.datosPersonales.get('cliente_telefono')?.value,
      cliente_celular: this.datosPersonales.get('cliente_celular')!.value,

    }
    console.log(payload);

  }

  getValidationMessage(field: string): string {
    console.log('field', field);
    const control = this.datosPersonales.get(field);
    if (control && control.errors) {
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          return this.validationMessages[field][key];
        }
      }
    }
    return '';
  }


  getDatosCliente(){

    if( this.logIn ){
      const datosUsuario = JSON.parse(localStorage.getItem('authToken')!);
      this.datosUsuario = datosUsuario.data;
    }else{
      const campo = document.getElementById('login');
      const modal = new bootstrap.Modal(campo);
      modal.show();
      this.router.navigate(['/checkout']);
    }
  }

  isLogin(){
    const login$ = this.authService.islogIn$.subscribe(login=>{
      this.logIn = login;
    });
  }

  ngOnDestroy(): void {
    if( this.login$){
      this.login$.unsubscribe();
    }
  }

}
