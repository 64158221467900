import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-repuesto-card',
  templateUrl: './repuesto-card.component.html',
  styleUrls: ['./repuesto-card.component.css']
})
export class RepuestoCardComponent {


  @Input() titulo: string = '';
  @Input() isDisabled = false;
  @Input() dataRepuestos: any = [];
  @Output() handlerVerRepuesto = new EventEmitter<any>();
  @Output() handlerAdd = new EventEmitter<any>();;

  onHandleAdd(id: any){
    this.handlerAdd.emit(id);
  }

  onHandleVer(id: any){
    this.handlerVerRepuesto.emit(id);
  }


}
