<div class="container">

    <div class="divLoading" *ngIf="showLoading">
        <app-loading srcImg="../../../assets/img/alaslogo.webp" />
    </div>
    

    <div class="mt-5">
        <app-progress-compra 
        [datosPersonales]="{'width':'33%', 'color': 'black', 'background-color':'#B3B2AE','min-width':'200px'}" 
        [pagar]="{'width': '34%','color': 'black', 'background-color':'#B3B2AE', 'min-width':'200px'}" 
        divActivo = 'div-pb-3'
        />
    </div>

    <div class="table-responsive mt-3">
        <app-tabla-pedido 
           [data] = "listaRepuestos"
           [btnEliminar] = false 
           [btnAddDel] = false 
           [producto] = false 
        />

        <div>
            <app-tabla-resumen-iva 
            [importe]="getImporte() | currency"
            [totalPrice]="getTotalPrice() | currency:'USD':'symbol':'1.0-0'"
            [IVA]="getIVA() | currency"
            />
        </div>
    </div> 


    <div class="div-pagar">

        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" (click)="okTerminos($event)" #chkConfirmar>
            <label class="form-check-label" for="flexCheckIndeterminate">
              Acepto 
              <a [href]="urlTYC | secureUrl" target="_blank">términos y condiciones</a>
            </label>
        </div>

        <div *ngIf="msg_entrega">
            <ol>
                <li>{{title_tiempo_entrega}}</li>
                <li><i class="bi bi-check"></i><span style="color: red;">{{msg_tiempo_entrega}}</span></li>
            </ol>
        </div>

        <div class="btn-pagar">
            <button class="btn btn-primary btn-regreso btn-hover" (click)="atras()">Atrás</button>
            <app-payu 
                [disabled]="logged|async" 
                [deshabilitar]="deshabilitar" 
                [referenceCode]="referencePago"
                [amount] = "getTotalPrice()"
                [tax] = "getIvaSinSeparadorMiles()"
                [taxReturnBase] = "0"
                [signature]="signature"
                [codigo_cliente_tienda]="usuario_cliente"
                [referencia_producto]="referencia_producto"
            /> 
        </div>
        
        <div class="d-flex justify-content-center mt-5">
            <p class="text-center">
                {{frase}}
            </p>
        </div>
        
    </div>


</div>