import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  firstValueFrom, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import Swal from 'sweetalert2';
import { Usuario} from 'src/app/models/usuario.models';
import { UsuarioService } from 'src/app/services/usuario.service';
import * as $ from 'jquery';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.services';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';

declare var bootstrap: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('myModal') myModal: any;
  @ViewChild('myModals') myModals: any;

  //SUBSCRIPCIONES
  addCarritoDB$: Subscription = new Subscription();
  getProductosBD$: Subscription = new Subscription();
  registro$: Subscription = new Subscription();
  autenticacionLogin$: Subscription = new Subscription();

  como_comprar = environment.URL_VIDEO_COMPRAR;
  nuestros_vehiculos = environment.URL_NUESTROS_VEHICULOS;

  isloading = false;

   Usuario= new Usuario();
   cliente_tipoidentificacion?: string;
   cliente_tipopersona?: string;
   cliente_identificacion?: string;
   cliente_apellido_1?:string;
   cliente_apellido_2?: string;
   cliente_nombre_1?: string;
   cliente_nombre_2?: string;
   cliente_departamento?: string;
   cliente_ciudad?: string;
   usuario_email?: string;
   cliente_direccion?: string;
   cliente_telefono?: string;
   cliente_celular?: string;
   cliente_fnacimiento?: string;
   usuario_password?:string;
   login?:string;
   password?:string;
   isMenuOpen = false;
   
   form: FormGroup = this.fb.group({
    log: ['', [Validators.email] ], 
    password: ['', [Validators.minLength(4)]]
  });
  
 
  constructor( private usuarioService: UsuarioService,  private fb: FormBuilder, public authService: AuthService, 
               public carrocompraService: CarrocompraService, private utilService: UtilService, private eRef: ElementRef ) {
  }
 
  ngOnInit(): void {
    this.form.reset();
    this.authService.login(this.authService.isLoggedIn())
  }
  
  @HostListener('document:click', ['$event'])
  handlerClick(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }


  /*********** INICIAR SESION **************** */
  Login(){
    this.login =this.form.get('log')?.value;
    this.password =this.form.get('password')?.value;
    
    if (this.login == null || this.password == null){
      this.utilService.showAlert('El login o pssword no pueden ser nulos','Advertencia!','warning');
      return;
    }

    const payload = {
      login: this.login,
      password: this.password
    }

    this.isloading = true;

    this.autenticacionLogin$ = this.usuarioService.autenticacionLogin(payload).subscribe(response => {
      this.isloading = false;
      this.authService.login(true);
      
      if(response.state === true){ 
        this.authService.login(true);
        this.authService.setAuth(response); 
        this.getProductosCarritoDB();
        this.form.reset();
        const modalElement = this.myModal.nativeElement;
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
     
    } else{
        this.utilService.showAlert('El login o pssword no pueden ser nulos','Error!','error');
      }
        
    }, (error: HttpErrorResponse)=>{

      let mensaje = '';
      this.isloading = false;
      console.log('error',error);

      if( typeof(error.error) == 'object' ){
        mensaje = error.error.message;
      }else{
        mensaje = error.message;    
      } 

      this.utilService.showAlert(mensaje,'Error!','error');

    });
 
    (window as any).Login = this.Login; 
 
  }

  
  /********* CERRAR SESION ************ */
  logout(){
    this.authService.login(false);
    this.authService.removeKeylocalStorage('authToken');
    let  carritoLocalStore = this.carrocompraService.getItem('carrito');
    
    if(carritoLocalStore == null){
      carritoLocalStore = [];
    }
    this.carrocompraService.setItemsCarritoBD(carritoLocalStore);
    this.carrocompraService.updateCantidadItemsCarrito(carritoLocalStore);
  }

 
  /******* OBTENER LOS PRODUCTOS DEL CARRITO DEL CLIENTE DE LA BASE DE DATOS *******/
  getProductosCarritoDB(){
    const usuario = this.authService.getUsuario();

    if(usuario == null){
      return;
    }

    const payload = usuario.data.usuario_cliente;
    this.getProductosBD$ = this.carrocompraService.getProductosBD(payload).subscribe((resp: any)=>{

      if(resp.state){
        this.migrarItemsCarritoLocalStorare(resp.data)
      }

    }, (error: HttpErrorResponse)=>{

      console.log('error',error);
      if(typeof(error.error) == 'object'){
        if( error.status == 404 ){
          this.addItemCarritoFromls();
        }
        
      }else{
 
        this.utilService.showAlert(error.message,'Error!','error');
      }

      
    });
  }

  /*********** MIGRAMOS LOS PRODUCTOS DEL LOCALSTORA A BASE DE DATOS *************** */
  migrarItemsCarritoLocalStorare(productos: any){
    this.carrocompraService.setItemsCarritoBD(productos);
    let carritols = this.carrocompraService.getItem('carrito');

    if( carritols == null){
      return;
    }
    
    this.carrocompraService.removeLocalStore('carrito');  

    carritols.map(async(resp:any)=>{
      await this.buscarItemCarritoDB(resp);
    });
  }


   /************ BUSCAMOS EL REPUESTO SI YA ESTA ADICIONADO EN EL CARRITO DE BASE DE DATOS ******************* */
  async buscarItemCarritoDB(producto: any) {
    const carritoBD = this.carrocompraService.getItemsCarritoBD();
    let cantidad = 0;
    let item: any = carritoBD.filter((resp: any) => {
      if (resp.id == producto.id) {
        cantidad = resp.cantidad + producto.cantidad;
        return resp;
      }
    });

    //si existe actualizamos la cantidad
    if (item.length > 0) {
      producto = { ...producto, cantidad };
      await this.updateCarritoDb(producto);

    } else {
      await this.addCarritoDb(producto);
    }
  }



  /************ ADICIONAMOS AL CARRITO DE BASE DE DATOS DESDE EL LOCALSTORAGE ***************** */
  addItemCarritoFromls(){
    let carritols = this.carrocompraService.getItem('carrito');
    if (carritols == null ){
      return;
    }
    this.carrocompraService.removeLocalStore('carrito');
    this.carrocompraService.setItemsCarritoBD([]);

    carritols.map(async(resp: any )=>{
      await this.addCarritoDb(resp);
    });

  }


  /************* ADICIONAR ITEMS AL CARRITO BASE DE DATOS ******************* */
  async addCarritoDb(producto: any) {
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }

    const payload = [{
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.precio,
      //carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    }];

    try {
      const resp:any = await firstValueFrom(this.carrocompraService.addCarritoDB(payload));
      if(resp.state){
        let carrito = this.carrocompraService.getItemsCarritoBD();
        carrito = [...carrito, producto];
        this.carrocompraService.setItemsCarritoBD(carrito);
        this.carrocompraService.updateCantidadItemsCarrito(carrito);
      }

    } catch (error) {
      console.log('error',error);  
    } 

  }


  /***************** ACTUALIZAMOS ITEM CARRITO BASE DE DATOS ************************************* */
  async updateCarritoDb(producto: any){
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      return;
    }
    
    const item = {
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.precio,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    };
    
    const payload = {
      id: producto.id,
      cliente: usuario.data.usuario_cliente,
      producto: item
    }
   
    try {
      const resp:any = await firstValueFrom(this.carrocompraService.updateItemCarritoBD(payload));
      if(resp.state){
        let productos = this.carrocompraService.getItemsCarritoBD();
        productos = productos.map((resp: any)=>{
          if(resp.id == producto.id){
            return {...resp, cantidad: producto.cantidad};
          }
          return resp;
        });
        
        this.carrocompraService.setItemsCarritoBD(productos);
        this.carrocompraService.updateCantidadItemsCarrito(productos);
      }
      
    } catch (error) {
      console.log('error',error);    
    }
  }


  /********** CERRAMOS TODAS LAS SUBSCRIPCIONES************ */
  ngOnDestroy(): void {
    if( this.addCarritoDB$  ){
       this.addCarritoDB$.unsubscribe(); 
    }
    if( this.getProductosBD$  ){
       this.getProductosBD$.unsubscribe(); 
    }
    if( this.registro$  ){
       this.registro$.unsubscribe(); 
    }
    if( this.autenticacionLogin$  ){
       this.autenticacionLogin$.unsubscribe(); 
    }
  }

  cerrarmodal(){

    const modalVideo = document.getElementById('exampleModalvideo') as HTMLElement;
    const iframe = modalVideo.querySelector('iframe') as HTMLIFrameElement;

    modalVideo.addEventListener('hidden.bs.modal', () => {
      iframe.src = ''; 
    });

    modalVideo.addEventListener('hidden.bs.modal', () => {
      iframe.src = this.como_comprar; 
    });

  }
}
