import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { catchError, firstValueFrom, of, Subscription } from 'rxjs';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { FiltrosService } from 'src/app/services/filtros.service';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/services/auth.services';
import { msg } from 'src/app/interfaces/mensajes.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-repuestos',
  templateUrl: './repuestos.component.html',
  styleUrls: ['./repuestos.component.css']
})

export class RepuestosComponent implements OnInit, OnDestroy {

  getReferencia$: Subscription = new Subscription();
  getPalabra$: Subscription = new Subscription();
  getLinea$: Subscription = new Subscription();
  getOem$: Subscription = new Subscription();
  addCarritoDB$: Subscription = new Subscription();
  updateItemCarritoBD$: Subscription = new Subscription();
  getProductosBD$: Subscription = new Subscription();
  
  productosnew: any[] = [];
  showLoading: boolean = false;
  vin: any[] = [];
  producto: any;
  busquedaPalabra: any = [];
  nombreOem: any[] = [];
  inputBusqueda!: any;
  numeroLinea!: any;
  noLinea: any;
  url: any;
  productos: any;
  datoEnviado: any;
  isDisabled = false;
  mensajes = msg;
  
  constructor(private busquedaService: BusquedaService, private filtroService: FiltrosService, 
              private router: ActivatedRoute, private routers: Router, private carrodecompraService: CarrocompraService, 
              private utilService: UtilService, private authService: AuthService) {

    this.url = environment.URL_S3_AWS;

    this.router.params.subscribe(params => {
      this.numeroLinea = (params['inputbusqueda']);
    })
    this.noLinea = this.numeroLinea;
  }

  ngOnInit(): void {
    this.obtenerNombreConLinea();
    this.obtenerOem();
    this.datoNumeroLinea();
    this.obtenerProducto()
    this.getProductosCarritoDB();
  }

  recibirDato(dato: any) {
    this.busquedaPalabra = dato;
  }

  datoNumeroLinea() {
    this.busquedaService.setNumerolinea(this.noLinea);
  }

  // funcion para activar y desactivar el loading
  mostrarLoading() {
    this.showLoading = true;
  }

  ocultarLoading() {
    this.showLoading = false;
  }


  obtenerNombreConLinea() {

    this.busquedaService.vin$.subscribe({
      next: (resp)=>{
        this.vin = resp;
      }
    })

    if( this.vin.length > 0){
      return;
    }

    this.mostrarLoading();
    this.getLinea$ = this.busquedaService.getLinea(this.numeroLinea)
      .pipe(
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          this.ocultarLoading();
          return of([]);
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === "true") {
          this.ocultarLoading()
          this.vin = response.datos.retorno.map(
            (elemento: { linecome_descripcomercial: any }) =>
              elemento.linecome_descripcomercial
          ).filter((nombre: any, indice: any, array: string | any[]) => array.indexOf(nombre) === indice);

          this.busquedaService.setVin(this.vin);
        }
        else {
          console.error('La solicitud a la API no fue exitosa');
          this.ocultarLoading();
        }

      });
  }



  obtenerOem() {
    //Busqueda OEM
    this.busquedaService.getRepuestosOEM().subscribe({
      next: (resp)=>{
         console.log('resp',resp);
         this.nombreOem = resp;
      }
    });

    //Busqueda por palabra
    this.busquedaService.getRepuestosPalabra().subscribe({
      next: (resp)=>{
        this.busquedaPalabra = resp;
      }
    });

    if (this.nombreOem.length > 0){
      return;
    }

    this.getOem$ = this.busquedaService.getOem(this.numeroLinea)
      .pipe(
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          this.ocultarLoading();
          return of([]); // Devuelve un observable vacío en caso de error
        })
      )
      .subscribe((response: any) => {
        if (response && response.success === "true") {
          this.nombreOem = response.datos.retorno.map(
            (elemento: any) =>
              elemento
          );

          this.busquedaService.setRepuestosOEM(this.nombreOem); 

        }


        else {
          console.error('La solicitud a la API no fue exitosa');
          this.ocultarLoading();
        }
      });
  }


  async getRepuestos(producto: any) {
    
    if (typeof (producto) != 'object') {
      this.utilService.showAlert('Repuesto no válido', 'Advertencia!', 'warning');
      return;
    }

    this.isDisabled = true;
    this.mostrarLoading();
    
    const nuevoProducto = {
      id: producto.referencia_codigo,
      cantidad: 1,
      caracteristicas: producto.referencia_caracteristica,
      codigo: producto.referencia_codigo,
      descripcion: producto.linecome_descripcomercial,
      imagen: producto.url_imagen,
      iva: producto.referencia_iva,
      marca: producto.referencia_marca,
      nombre: producto.referencia_nombre,
      precio: producto.referencia_preciovigente,
      preciosiniva: producto.referencia_preciovigentesiniva
    };

    const data = await this.getDisponible(producto.referencia_codigo);
    
    if(data == null){
      this.utilService.showAlert('El repuesto no se encuentra disponible.','Advertencia!','warning');
      this.isDisabled = false;
      this.ocultarLoading();
      return;
    }
   
    if (this.authService.isLoggedIn()) {
      this.buscarItemCarritoDB(nuevoProducto);
    } else {
      this.addCarritoLocal(nuevoProducto);
    }

  }

  async getDisponible(payload: string){
    let disponible: any;
    let cantidadServ: any;
    let filter: any;
    try {

      let productosCarrito = [];
      disponible = await firstValueFrom(this.busquedaService.getReferencia(payload));
      cantidadServ  = disponible.datos.refebode_disponible;
      
      if(cantidadServ== 0){
        return null;
      }

      if(this.authService.isLoggedIn()){
        productosCarrito = this.productosnew;
      }else{
        productosCarrito = this.carrodecompraService.getItem('carrito');
        if(productosCarrito == null){
          productosCarrito = [];
        }
      }
      
      if(productosCarrito.length > 0){
        filter = productosCarrito.filter((resp:any)=>resp.id == payload);
        if(filter.length == 0){
          return cantidadServ;
        }

        const dif = cantidadServ - filter[0].cantidad;
        return dif > 0 ? dif : null;

      }else{
        return cantidadServ;
      }

    } catch (error) {
      return null; 
    }
   
  }

  addCarritoLocal(payload: any) {
    this.carrodecompraService.AñadirCarroCompra(payload);
    this.ocultarLoading();
    this.isDisabled = false;
    this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
  }

  /************ BUSCAMOS EL REPUESTO SI YA ESTA ADICIONADO EN EL CARRITO DE BASE DE DATOS ******************* */
  buscarItemCarritoDB(producto: any) {
    let cantidad = 0;
    let item: any = this.productosnew.filter((resp: any) => {
      if (resp.id == producto.id) {
        cantidad = resp.cantidad + producto.cantidad;
        return resp;
      }
    });

    //si existe actualizamos la cantidad
    if (item.length > 0) {
      producto = { ...producto, cantidad };
      this.updateCarritoDb(producto);
    } else {
      this.addCarritoDb(producto);
    }
  }

  /************* ADICIONAR ITEMS AL CARRITO BASE DE DATOS ******************* */
  addCarritoDb(producto: any) {
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      this.isDisabled = false;
      return;
    }
    this.mostrarLoading();

    const payload = [{
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: 1,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    }];

    this.addCarritoDB$ = this.carrodecompraService.addCarritoDB(payload).subscribe((resp: any) => {
      this.ocultarLoading();
      this.isDisabled = false;
      if (resp.state) {
        this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
        this.productosnew = [...this.productosnew, producto];
        this.updateCantidadItemsCarrito(this.productosnew );
      }


    }, (error: HttpErrorResponse) => {
      console.log('error', error);
      this.ocultarLoading();
      this.isDisabled = false;
      if (typeof (error.error) == 'object') {
        this.utilService.showAlert(error.error.message, 'Error!', 'error');
      } else {
        this.utilService.showAlert(error.message, 'Error!', 'error');
      }
    });

  }

  /***************** ACTUALIZAMOS ITEM CARRITO BASE DE DATOS ************************************* */
  updateCarritoDb(producto: any){
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      this.utilService.showAlert('No hay un usuario válido en sesión.', 'Advertencia!', 'warning');
      this.isDisabled = false;
      return;
    }
    
    const item = {
      carcomp_cliente: usuario.data.usuario_cliente,
      carcomp_producto: producto.codigo,
      carcomp_cantidad: producto.cantidad,
      carcomp_precio: producto.precio,
      carcomp_prodnomb: producto.nombre,
      carcomp_imagen: producto.imagen,
      //carcomp_tamanno: 0,
      carcomp_proddescrip: producto.descripcion,
      carcomp_marca: producto.marca,
      carcomp_caracteristicas: producto.caracteristicas,
      carcomp_presiniva: producto.preciosiniva,
      carcomp_iva: 0,
      carcomp_restriccion: '',
      carcomp_unidadminima: 1,
      carcomp_estado: 'AC'
    };
    
    const payload = {
      id: producto.id,
      cliente: usuario.data.usuario_cliente,
      producto: item
    }
    
    this.mostrarLoading();
    this.updateItemCarritoBD$ = this.carrodecompraService.updateItemCarritoBD(payload).subscribe((resp: any)=>{
      this.ocultarLoading();
      this.isDisabled = false;
      if(resp.state){
        this.utilService.showAlert('Has añadido un respuesto a tu carro de compras', 'Exito!', 'success');
          this.productosnew = this.productosnew.map((resp: any)=>{
          if(resp.id == producto.id){
            return {...resp, cantidad: producto.cantidad};
          }
          return resp;
        });
      }

      this.updateCantidadItemsCarrito(this.productosnew);

    }, (error: HttpErrorResponse)=>{
      this.ocultarLoading();
      this.isDisabled = false;
      console.log('error',error);
      if(typeof(error.error) != 'object' ){
        this.utilService.showAlert(error.message,'Error!','error');
      }else{
        this.utilService.showAlert(error.error.message,'Error!','error');
      }
    });
  }


  getImagenId(id: any) {
    this.inputBusqueda = id;
    this.routers.navigate(['/repuesto', this.inputBusqueda, ]);
  }


  obtenerProducto() {
    this.productos = this.filtroService.setSubgrupoSeleccionado(this.producto);
  }


  /******* OBTENER LOS PRODUCTOS DEL CARRITO DEL CLIENTE DE LA BASE DE DATOS *******/
  getProductosCarritoDB() {
    const usuario = this.authService.getUsuario();

    if (usuario == null) {
      return;
    }

    const payload = usuario.data.usuario_cliente;

    this.getProductosBD$ = this.carrodecompraService.getProductosBD(payload).subscribe((resp: any) => {
      if(resp.state){
        this.productosnew = resp.data;
      }

    }, (error: HttpErrorResponse) => {

      console.log('error', error);
      let msg = '';
      if (typeof (error.error) != 'object') {
        msg = error.message;
        this.utilService.showAlert(msg, 'Error!', 'error');
      }
    });
  }


  updateCantidadItemsCarrito(productos: any){
    this.carrodecompraService.updateCantidadItemsCarrito(productos);
 }


  onkeyUpSearch(event: KeyboardEvent) {
    const elementoHtml = event.target as HTMLInputElement;
    this.datoEnviado = elementoHtml.value;
    
    if (event.key == 'Enter') {
        this.buscarPorPalabra();
    }
  }
  
  
  buscarPorPalabra (){
    this.busquedaPalabra = [];
    this.busquedaService.setRepuestosPalabra([]);

    if(!(this.datoEnviado).trim()){
      this.datoEnviado = '';
      this.utilService.showAlert('Debe ingresar producto','Advertencia!','warning');
    }
    
    this.mostrarLoading();
    this.busquedaService.getPalabra(this.datoEnviado).subscribe({
      next: (resp)=>{
        this.ocultarLoading();
        if( resp.datos.retorno.length > 0  ){
          this.busquedaPalabra = resp.datos.retorno
          this.busquedaService.setRepuestosPalabra(this.busquedaPalabra);
        }else{
          this.utilService.showAlert(this.mensajes.MSG_SIN_PRODUCTOS,'Advertencia!','warning');
        }
      },
      error:(error)=>{
        this.ocultarLoading();
        console.log('error',error);
      }
    });

  }


  /********** CERRAMOS TODAS LAS SUBSCRIPCIONES************ */
  ngOnDestroy(): void {
    if( this.getReferencia$ ){
       this.getReferencia$.unsubscribe(); 
    }
    if( this.getPalabra$  ){
       this.getPalabra$.unsubscribe(); 
    }
    if( this.getLinea$  ){
       this.getLinea$.unsubscribe(); 
    }
    if( this.getOem$  ){
       this.getOem$.unsubscribe(); 
    }
    if( this.addCarritoDB$  ){
       this.addCarritoDB$.unsubscribe(); 
    }
    if( this.updateItemCarritoBD$  ){
       this.updateItemCarritoBD$.unsubscribe(); 
    }
    if( this.getProductosBD$  ){
       this.getProductosBD$.unsubscribe(); 
    }
  }

}