import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IRepuesto } from '../interfaces/repuesto.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.services';
import { UtilService } from './util.service';
import { IResponsePedidoSiisa } from '../interfaces/pedido-siisa.interface';


@Injectable({
  providedIn: 'root'
})


export class CarrocompraService {

  productosnew: any[] = [];

  private dataRepuesto : IRepuesto[] = [];
  private key = environment.SIISA_KEY;
  URL_API = environment.API_BACKEND;
  API_SIISA_PD = environment.API_SIISA_PD;

  private itemsCarrito = new BehaviorSubject<any[]>([]);
  itemsCarrito$ = this.itemsCarrito.asObservable();

  private cant_carrito =  new BehaviorSubject<number>(0); 
  cant_carrito$ = this.cant_carrito.asObservable();
  
  constructor(private http: HttpClient, private authService: AuthService, private utilService: UtilService) { 
    this.totalItem();
  }
  

  /***** AÑADIR PRODUCTOS AL CARRITO LOCALSTORAGE ******** */
  AñadirCarroCompra(repuesto: IRepuesto):void{
    const carrito = this.getItem('carrito');

    if(carrito != null){
      const result = this.addItem(repuesto, carrito);
      this.dataRepuesto = result;
    }else{
      this.dataRepuesto.push(repuesto);
    }
    
    if(this.dataRepuesto.length > 0){
      this.setItem('carrito', this.dataRepuesto );
      this.totalItem();
    }
  }

  addItem(item: any, carrito: any){
    const existe =  carrito.find((products: any)=>{
      return products.id == item.id
    });

    if(existe){
      existe.cantidad += item.cantidad;
      return carrito;
    }else{
      this.dataRepuesto.push(item);
      return this.dataRepuesto;
    }
  }


  getDataProducto(): IRepuesto[]{
    return this.dataRepuesto;
  }


  /************ OBTENER KEY DEL LOCALSTORAGE ***********************/
  getItem(item: string){
    const data = localStorage.getItem(item);
    if(!!data){
      return JSON.parse(data);
    }
    return null;
  }
  

  /*********** ADICIONAR CLAVE DEL LOCALSTORAGE ***************/
  setItem(item: string, data: any){
    localStorage.setItem(item, JSON.stringify(data));
  }

  /***************** REMOVER ITEM DEL LOCALSTORAGE ***************** */
  removeItem(id: string){
    const datos = this.dataRepuesto.filter((items:any)=>{
      return items.id != id;
    });
    this.setItem('carrito', datos );
    this.totalItem();
  }

  /*********** REMOVER CLAVE DEL LOCALSTORAGE ***************/
  removeLocalStore(key: string){
    localStorage.removeItem(key);
  }


  totalItem(){
    const item = this.getItem('carrito');
    let cantidad = 0;
   
    if(!!item){
      this.dataRepuesto = item;
      const total = Object.values(item).reduce((accumulator, dato: any)=>{
        return accumulator + dato.cantidad;
      },0);

      cantidad = total as number;
      this.cant_carrito.next(cantidad);
    }
  }

  
  getRepuestos(){
    return this.dataRepuesto;
  }

  
  /***** OBTENER LOS PRODUCTOS DEL CARRITO DE BASE DE DATOS *****/
  getProductosBD(cliente_id: string){
    const API_BACKEND = `${this.URL_API}/carrito/itemsCliente/${cliente_id}`;
    
    return this.http.get(API_BACKEND).pipe(map((resp: any)=>{
      let totalItems = 0; 
      const data = resp.data.map((item:any)=>{
        totalItems = totalItems + parseInt(item.carcomp_cantidad);
        return {
          id: item.carcomp_producto,
          nombre: item.carcomp_prodnomb,
          cantidad: parseInt(item.carcomp_cantidad),
          precio: item.carcomp_precio,
          imagen: item.carcomp_imagen,
        }    
      });

      this.cant_carrito.next(totalItems);

      return {
              state: resp.state,
              message: resp.message,
              data
             };
    }));
  }

  /********* ADICIONAR PRODUCTOS A CARRITO BASE DE DATOS *************** */
  addCarritoDB(payload: any){
    const API = `${this.URL_API}/carrito/addItem`;
    return this.http.post(API, payload);
  }

   /********* ELIMINAR PRODUCTOS A CARRITO BASE DE DATOS *************** */
   deleteItemCarritoDB(payload: any){
    const API = `${this.URL_API}/carrito/deleteItem/${payload.id_producto}/${payload.cliente}`;
    return this.http.delete(API, payload);
  }

  updateItemCarritoBD(payload: any){
    const API = `${this.URL_API}/carrito/updateItem/${payload.id}/${payload.cliente}`;
    return this.http.put(API,payload.producto);
  }


  incrementCantidad(cant: number){
    this.cant_carrito.next(cant);
  }

  getCantidadItemCarrito(){
    return this.cant_carrito.getValue();
  }

  //store
  getItemsCarritoBD(){
    return this.itemsCarrito.getValue();
  }
  
  setItemsCarritoBD(product: any){
    this.itemsCarrito.next(product);
  }


  /******* ACTUALIZA LA CANTIDAD DE LOS PRODUCTOS EN EL CARRITO ************* */
  updateCantidadItemsCarrito(productos: any){

    if(productos.length == 0){
      this.incrementCantidad(0);
      this.dataRepuesto = [];
      return;
    }

    let cantidad = productos.reduce((acum:any, res:any)=>{
      return res.cantidad + acum;
    },0);

    this.incrementCantidad(cantidad);
  }

  /**********************  API GENERAR REFERENCIA PAGO PAYU ****************** */
  getSignaturePayu(payload: any){

    const URL = `${this.URL_API}/referencia`;
    return this.http.post(URL, payload );
  }


  /*************  REGISTRAR PEDIDO EN SIISA ************************** */
  crearPedidoSiisa(payload: any): Observable<IResponsePedidoSiisa>{
    
    const URL = this.API_SIISA_PD;

    const params = new HttpParams()
      .set('p_access', this.key)
      .set('action', '_getgenerapedido');
      
    return  this.http.post<IResponsePedidoSiisa>(URL,payload,{params});
  }
  

  crearLogSiisaPasarela(payload: any){
    
    const URL = this.API_SIISA_PD;

    const params = new HttpParams()
      .set('p_access', this.key)
      .set('action', '_InstLogPgonline')
      .set('paonconf_usuarioid', payload.merchantId)
      .set('paonconf_estadopol', payload.transactionState)
      .set('paonconf_riesgo', payload.risk)
      .set('paonconf_codigorespupol', payload.polResponseCode)
      .set('paonconf_refventa', payload.referenceCode)
      .set('paonconf_refpol', payload.reference_pol)
      .set('paonconf_firma', payload.signature)
      .set('paonconf_extra1', payload.extra1)
      .set('paonconf_extra2', payload.extra2)
      .set('paonconf_mediopago', payload.polPaymentMethod)
      .set('paonconf_tipomediopago', payload.polPaymentMethodType)
      .set('paonconf_cuotas', payload.installmentsNumber)
      .set('paonconf_valor', payload.TX_VALUE)
      .set('paonconf_iva', payload.TX_TAX)
      .set('paonconf_valoradicional', payload.paonconf_valoradicional)
      .set('paonconf_moneda', payload.currency)
      .set('paonconf_fechatransaccion', payload.processingDate)
      .set('paonconf_codigoautorizacion', payload.authorizationCode)
      .set('paonconf_cus', payload.cus)
      .set('paonconf_bancopse', payload.pseBank)
      .set('paonconf_emailcomprador', payload.buyerEmail)
      .set('paonconf_tasacambio', payload.TX_ADMINISTRATIVE_FEE)
      .set('paonconf_ip', payload.paonconf_ip)
      .set('paonconf_pagorecurrente', payload.paonconf_pagorecurrente)
      .set('paonconf_numerovisible', payload.paonconf_numerovisible)
      .set('paonconf_tarjetahabiente', payload.paonconf_tarjetahabiente)
      .set('paonconf_franquicia', payload.lapPaymentMethod)
      .set('paonconf_estado', payload.paonconf_estado)
      
    return  this.http.post<any>(URL, null,{params});
  }
  

  updateEstadoCarrito(payload: any){
    const URL = `${this.URL_API}/carrito/updateCarrito`;
    return this.http.post(URL, payload);
  }

}