import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAuth, usuarioRegistro } from '../interfaces/auth.interfece';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})



export class UsuarioService {
    
  API_BACKEND = environment.API_BACKEND; 
  pass: any;

  constructor(private httpClient: HttpClient) { }
    
  registro(payload: usuarioRegistro ){
    const apiUrlRegistro= `${this.API_BACKEND}/auth/registrar`;
    return this.httpClient.post<any>(apiUrlRegistro, payload );
  }
   
    
  autenticacionLogin( payload: any ){ 
    const apiUrlLogin= `${this.API_BACKEND}/auth/login`;
    return this.httpClient.post<IAuth>(apiUrlLogin, payload );
    
  };

  recordarPassword(payload: any){
    const apiUrl= `${this.API_BACKEND}/auth/recordar`;
    return this.httpClient.post(apiUrl, payload);
  }
  
  resetPassword(payload: any){
    const apiUrl= `${this.API_BACKEND}/auth/reset-password/${payload.token}`;
    return this.httpClient.post(apiUrl, payload.password);
  }
 
}