<div class="container d-flex flex-column justify-content-center">
  
  <div style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;display: flex;align-items: center;
              justify-content: center;background-color: rgba(255, 255, 255, 0.8); z-index: 1000;"  *ngIf="showLoading">
    <app-loading srcImg="../../../assets/img/alaslogo.webp" />
  </div>

  <div class="row">
      <ul class="d-flex justify-content-between align-items-center flex-wrap mt-5  gap-2">
        <li class="d-flex align-items-center justify-content-center gap-2">
          <a routerLink="home" class="btn btn-hover" style="background-color:#FFF; color: #481F71; width: 180px; justify-content: center; align-items: center; border-color: #481F71; cursor: pointer;">
            <i class="bi bi-search"></i>
              Ir a la busqueda
          </a>
          <h4 style=" padding-top: 10px;">{{vin}}</h4>  
        </li>
      </ul>
  </div>

  <hr style="color:#481F71 !important;">

  <div class="d-flex align-items-center">
    <div class="input-group flex-nowrap m-3">
      <span class="input-group-text">
        <i class="bi bi-search"></i>
      </span>
      <input type="text" class="form-control" id="palabraSearch" placeholder="Producto a consultar..." aria-label="Username"
        aria-describedby="addon-wrapping" (keyup)="onkeyUpSearch($event)">
      <br>
    <button class="c_Search"  (click)="buscarPorPalabra()" aria-label="Close" data-bs-dismiss="offcanvas">Buscar</button>
    </div>
  </div>

  <!--COMPONENTE SIDEBAR -->
  <app-sidebar (enviarDato)="recibirDato($event)"></app-sidebar>

  <div class="container d-flex justify-content-center gap-3 div-card">

    <div  *ngIf="busquedaPalabra.length > 0">  
      <app-repuesto-card 
        titulo="Repuestos originales"
        [dataRepuestos]="busquedaPalabra"
        (handlerVerRepuesto) = "getImagenId($event)"
        [isDisabled] = "isDisabled"
        (handlerAdd) = "getRepuestos($event)"
      />
    </div>
    
    <div  *ngIf="nombreOem.length > 0" class="row d-flex justify-content-center mb-5">
      <app-repuesto-card 
        titulo="Repuestos OEM"
        [dataRepuestos]="nombreOem"
        (handlerVerRepuesto) = "getImagenId($event)"
        [isDisabled] = "isDisabled"
        (handlerAdd) = "getRepuestos($event)"
      /> 
    </div>

  </div>

</div>