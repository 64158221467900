import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `<div class="spinner-border" [ngClass]="color" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>`
})
export class SpinnerComponent {

  @Input() color = 'text-primary'; 

}
