<div class="container rounded">
    <ul class="d-flex flex-column nav nav-tabs bg-light rounded" id="myTab" role="tablist">
        <div class="d-flex justify-content-center">
            <h5 class="mt-3">Accede fácilmente a los repuestos SsangYong</h5>
        </div>
        <hr>
        <div class="d-flex flex-wrap">
            <li class="nav-item" role="presentation" *ngFor="let i of filtros; let index = index; ">
                 <a class="nav-link {{i.active ? 'active' : ''}}" id="{{i.id}}" 
                     data-bs-toggle="tab" 
                     [attr.href]="'#' + i.tab"
                     role="tab" 
                     [attr.aria-controls]="i.tab" 
                     aria-selected="i.selected" 
                     (click) = "activarTab(index+1)">
                    {{i.nombre}}
                 </a>
             </li>
        </div>
    </ul>
    
    <div class="tab-content mt-1 pt-3 pb-3 bg-light rounded d-flex flex-column align-items-center" id="myTabContent">
        <!-- TAB PLACA -->
        <ng-container *ngIf="tabNumber == 1">
            <div class="tab-pane d-flex flex-column fade show active " id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                        <i class="bi bi-search"></i>
                    </span>
                    <input  
                        [(ngModel)]="placa.valor"
                        type="text" 
                        id="busqueda1" 
                        name="busqueda" 
                        placeholder="Ingresa tu placa" 
                        class="form-control" 
                        required
                        aria-describedby="basic-addon1"
                        [ngClass]="invalid"
                    >
                    
                </div>
                <small *ngIf="invalid">
                    La {{ placa.label }} no puede estar vacía.
                </small>
                <div class="d-placa">
                    <span>
                        La placa se encuentra ubicada en la tarjeta de propiedad del vehículo.
                    </span>
                    <span>Ejemplo: ABC123</span>
                </div> 
            </div>
        </ng-container>
         <!-- TAB VIN -->
        <ng-container *ngIf="tabNumber == 2">
            <div class="tab-pane  d-flex flex-column" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                        <i class="bi bi-search"></i>
                    </span>
                    <input  
                        [(ngModel)]="vin.valor"
                        type="text" 
                        id="busqueda1" 
                        name="busqueda" 
                        placeholder="Ingresa el VIN" 
                        class="form-control" 
                        required
                        aria-describedby="basic-addon1"
                        [ngClass]="invalid"
                    >
                </div>
                <small *ngIf="invalid">
                    El {{ vin.label }} no puede estar vacío.
                </small>
                <div class="d-placa">
                    <span>
                        El VIN se encuentra ubicada en la tarjeta de propiedad del vehículo.
                    </span>
                    <span>Ejemplo: 3FAHPOHA6AR123456</span>
                </div> 
            </div>
        </ng-container>    
        <!-- TAB REFERENCIA -->
        <ng-container *ngIf="tabNumber == 3">
            <div class="tab-pane d-flex flex-column"  id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                        <i class="bi bi-search"></i>
                    </span>
                    <input  
                        [(ngModel)]="ref.valor"
                        type="text" 
                        id="busqueda1" 
                        name="busqueda" 
                        placeholder="Ingresa la referencia" 
                        class="form-control" 
                        required
                        aria-describedby="basic-addon1"
                        [ngClass]="invalid"
                    >
                </div>
                <small *ngIf="invalid">
                    La {{ ref.label }} no puede estar vacía.
                </small>
                <div class="d-placa">
                    <span>
                        La referencia es el código del repuesto, como pastilla de frenos o filtros.
                    </span>
                    <span>Ejemplo: 2073034201</span>
                </div> 
            </div> 
        </ng-container>    
        <!-- TAB LINEAS -->
        <ng-container *ngIf="tabNumber == 4">
            <div class="d-flex flex-column flex-wrap  position-relative d-linea" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                
                <div class="div-loading" *ngIf="loading">
                    <app-spinner />
                </div>
                
                <div class="d-flex flex-wrap justify-content-center mt-3">
                    <div class="d-flex flex-column">
                        <label for="opcionesSelect" class="form-label">Marca<span>*</span></label>
                        <select class="select-linea control-xs" name="marca" required>
                            <option value="ssy">SSANYONG</option>
                        </select>
                    </div>
                    <div class="d-flex flex-column">
                        <label for="opcionesModelos" class="form-label">Modelos <span>*</span></label>    
                        <select class="select-linea control-xs" 
                                id="modelo"  
                                required  
                                [ngClass]="modelo_invalid"
                                (change)="obtenerFamilia($event)">
                            <option value="">Seleccione</option>
                            <option *ngFor="let m of modelos" value="{{m.vehiculo_annomodelo}}">{{m.vehiculo_annomodelo}} </option>
                        </select>
                    </div>  
                </div>
            
                <div class="form-group d-flex flex-wrap justify-content-center">
                    <div class="d-flex flex-column">
                        <label for="opcionesFamilia" class="form-label">Familia<span>*</span></label>
                        <select class="select-linea control-xs" [(ngModel)]="cmbfamilia" 
                                id="familia" name="dia" 
                                required 
                                [disabled]="loading" 
                                [ngClass]="familia_invalid"
                                (change)="obtenerCajaCambios($event)">
                            <option value="" selected>Seleccione</option>
                            <option *ngFor="let f of familias" value="{{f.tbnombline_codigo}}"> {{f.tbnombline_descripcion}}</option>
                        </select>
                    </div>
                    <div class="d-flex flex-column">
                        <label for="opcionesCaja" class="form-label">Caja de cambios<span>*</span></label>
                        <select class="select-linea control-xs" 
                                [(ngModel)]="cmbcaja"  
                                required 
                                [ngClass]="caja_invalid" 
                                (change)="obtenerCombustible($event)">
                        <option value="" selected>Seleccione</option>
                        <option id="caja"  *ngFor="let c of cajaCambios" value="{{c.tbtransmision_codigo}}"> {{c.tbtransmision_descripcion}}</option>
                        </select>
                    </div>
                </div>
            
            
                <div class="form-group d-flex flex-wrap justify-content-center">
                    <div class="d-flex flex-column">
                        <label for="opcionesCombustibles" class="form-label">Combustible<span>*</span></label>
                        <select class="select-linea control-xs" 
                                [(ngModel)]="cmbcombustible"  
                                name="combustible" id="combustible" 
                                required 
                                [ngClass]="combustible_invalid"
                                (change)="obtenerLinea($event)">
                        <option value="" selected>Seleccione</option>
                        <option id="caja"  *ngFor="let c of combustibles" value="{{c.tbtipomotor_codigo}}"> {{c.tbtipomotor_descripcion}}</option>
                        </select>
                    </div>
                    <div class="d-flex flex-column">
                        <label for="opcionesLineas" class="form-label">Linea<span>*</span></label>
                        <select class="select-linea control-xs" 
                                [(ngModel)]="cmblinea"  
                                name="linea" 
                                id="linea"
                                [ngClass]="linea_invalid"
                                (change)="selecionarLinea($event)"  
                                required >
                        <option value="" selected>Seleccione</option>
                        <option *ngFor="let l of lineas" value="{{l.linecome_codigo}}"> {{l.linecome_descripfabricante}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </ng-container>
        <button class="btn btn-primary button-search2 m-3" 
                style="background-color: #373151;" 
                (click)="handlerConsultar()">Buscar</button>
    </div>
</div>