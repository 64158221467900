<!-- TODO:validar los tipos de identificacion -->
<div class="container">

    <div>
        <app-progress-compra
            [datosPersonales]="{'width':'33%', 'color': 'black', 'background-color':'#B3B2AE', 'min-width':'200px'}" 
            divActivo = 'div-pb-2'
        />
    </div>
    
    <div class="d-flex flex-column justify-content-center align-items-center mb-5 mt-5">
        <h1>Datos de envío</h1>
        <app-form-cliente [campos]="datosUsuario" />
    </div>



</div>