import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { msg } from 'src/app/interfaces/mensajes.interface';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';

import { environment } from 'src/environments/environment';

declare var bootstrap: any;

@Component({
  selector: 'app-payu',
  templateUrl: './payu.component.html',
})
export class PayuComponent implements OnInit,OnDestroy {


  _deshabilitar:any; 
  _disabled:any;
  habilitar:any;
  modal:any;
  datosUsuario:any;
  carritoDetalle: any = [];
  buyerEmail      = '';
  merchantId      = environment.MERCHANTID;
  accountId       = environment.ACCOUNTID;
  currency        = environment.CURRENCY;
  test            = environment.TEST;
  responseUrl     = environment.RESPONSEURL;
  confirmationUrl = environment.CONFIRMATIONURL;
  apiKey          = environment.APIKEY;
  URL_PAYU        = environment.URL_PAYU;
  msg             = msg;
  isloading       = false;
  signature       = '';
  referenceCode   = '';
  referencePago   = '';
  msgModalBody    = '';
  referencia_producto = '';
    
  crearPedidoSiisa$: Subscription = new Subscription();
  getSignaturePayu$: Subscription = new Subscription();
  getPedidosCliente$: Subscription = new Subscription();
  

  @ViewChild('payuForm')      payuForm!: ElementRef<HTMLFormElement>;
  @ViewChild('modalConfirm')  modalConfirm!: ElementRef<HTMLFormElement>;
  @Input() amount = 0;
  @Input() tax: any ;
  @Input() taxReturnBase = 0;
  @Input() codigo_cliente_tienda = '';
  @Input() set disabled(value:any){
    this._disabled = value;
    this.habilitar = this.getHabilitar;
  };
  @Input() set deshabilitar(value:any){
      this._deshabilitar = value;
      this.habilitar = this.getHabilitar;
  };
  @Input() listaRepuestos: any = [];

  constructor(private utilSrv: UtilService, private carritoSrv: CarrocompraService, private busquedaSrv: BusquedaService){}
  
  ngOnInit(): void {
    this.getUsuario();  
  }
  
 
  get getHabilitar(){
    //Habilitar pagar
    if ( this._disabled && !(this._deshabilitar) ){
      return false; 
    }else{
      return true;
    }
  }

  async handlerPagar(){
    this.isloading = true;
    this.disabled = false;
    const msgCarrito = await this.validarDisponibilidad();
    
    if(msgCarrito){
      this.utilSrv.showAlert(msgCarrito,'Advertencia!','warning');
      this.disabled   = true;
      this.isloading  = false
      return;
    }else{
      this.getPedido();
    }
  }


  /************ CREAMOS EL PEDIDO EN SIISA ******************/
  crearPedidoSiisa(){

    this.getItemsCarrito();
    
    const items: any[] = this.carritoDetalle.map((i:any)=>{
      return i.referencia;
    });

    if ( items.length > 0 ){
      this.referencia_producto = items.join();
    }
    
    if( !this.datosUsuario ){
      this.isloading = false;
      this.disabled = true;
      return;
    }

    const cliente = this.datosUsuario.data.cliente.clierepu_codigo;
    
    const payload = {
      encabezado:[
        {
          empresa: "SSY",
          ClienteCodigo: cliente,
          numeropedido: 0,
          bodega: "02",
          tipopedido:"01",
          fpedido: new Date(),
          destino:"00"
        }
      ],
      observaciones: "",
      detalle: this.carritoDetalle
    }

    this.crearPedidoSiisa$ =this.carritoSrv.crearPedidoSiisa(payload).subscribe({
      next: (resp)=>{
        if(resp.datos[0].estadoproceso == "ROLLBACK"){
          this.utilSrv.showAlert(this.msg.MSG_ERROR_PEDIDO, 'Advertencia!', 'warning');
        }else{
          const datoPedidoPrevio = '00';
          this.referencePago = '';
          this.referencePago = `${resp.datos[0].detalle[0].detamovi_bodega}${datoPedidoPrevio}${resp.datos[0].detalle[0].detamovi_recoleccion}${resp.datos[0].detalle[0].detamovi_empresa}`;
          this.generarReferencia();
        }

      },
      error:(error:HttpErrorResponse)=>{
        //this.showLoading = false;
        this.isloading = false;
        this.disabled = true;
        const payloadAPI = {
          method: "crearPedidoSiisa" ,
          request_payload: JSON.stringify(payload),
          error_message: JSON.stringify(error) ,
          client_data: localStorage.getItem('authToken') 
        }
        
        this.utilSrv.crearErrorLog(payloadAPI).subscribe({
          next: (resp:any)=>{
           if(!resp.state){
              console.log('error',error);
            }
          },
          error: (error) =>{
            console.log('error',error);
          }
        });
      }
    });
  }


  /*********** GENERAR EL SIGNATURE PARA LA PASARELA ***************/
  generarReferencia(){
    const reference     = this.referencePago;
    this.referenceCode  = reference;
   
    const payload = {
      apiKey      : this.apiKey,
      merchantId  : this.merchantId,
      reference   : reference,
      amount      : (this.amount).toString(),
      currency    : this.currency
    }
    
    this.getSignaturePayu$ = this.carritoSrv.getSignaturePayu(payload).subscribe({
      next: (resp: any)=>{
        if( resp.state ){
          this.signature = resp.data;
          this.pagoPayu();
        }
      },
      error: (error)=>{
        this.isloading = false;
        this.disabled = true;
        console.log('error',error);
      }
    });
  }

  
  async validarDisponibilidad(){
    let msg:any = ''; 

    if(this.listaRepuestos.length == 0){
      this.utilSrv.showAlert(this.msg.MSG_CARRITO_SIN_ITEMS, 'Advertencia!', 'warning');
      this.isloading = false;
      return;
    }
    
    for (let x in this.listaRepuestos){
      try {
        let resp:any =  await this.busquedaSrv.getDisponibilidad(this.listaRepuestos[x].id);
        let msgResp = this.compararCantidad( this.listaRepuestos[x],resp.datos);
        
        if(msg){
          msg = `${msg} ${msgResp}`;
        }else{
          msg = msgResp;
        } 

      } catch (error) {
        this.isloading = false;
        this.disabled = true;
        console.log('error',error);
        return 'Error al consultar la disponibilidad';
      }
    }

    return msg;
  }



  /*********** COMPARA LA CANTIDA DEL CARRITO CON INVENTARIO SIISA ************/
  compararCantidad(itemCarrito: any, itemBodega:any){
    let msg = '';
    if(itemCarrito.cantidad > parseInt(itemBodega.refebode_disponible) ){
      msg = `${this.msg.MSG_REOUESTO_NO_DISPONIBLE} ${itemBodega.referencia_nombre} `;
    }
    return msg;
  }

 
   
  /************** REALIZA PAGO EN PASARELA PAYU *******************/
  pagoPayu(){
    const dataParams = {
      merchantId:      this.merchantId,
      accountId:       this.accountId,
      description:     this.msg.DESCRIPTION_PAYU,
      referenceCode:   this.referenceCode,
      amount:          (this.amount).toString(),  
      tax:             this.tax,
      taxReturnBase:   (this.taxReturnBase).toString(),
      currency:        this.currency,
      signature:       this.signature,
      test:            this.test,
      extra1:          this.codigo_cliente_tienda,
      extra2:          this.referencia_producto,
      buyerEmail:      this.buyerEmail,
      responseUrl:     this.responseUrl,
      confirmationUrl: this.confirmationUrl
    }
    
    setTimeout(() => {
      //this.isloading  = false;
      this.disabled   = true;
      this.payuForm.nativeElement.submit();
    }, 500);
  }

  
  /********* VALIDAR PEDIDOS DEL CLIENTE ***************/
  getPedido(){

    const payload = {
      nit: this.datosUsuario.data.cliente.cliente_identificacion
    }
  
    this.getPedidosCliente$ = this.carritoSrv.getPedidosCliente(payload).subscribe({
      next: (resp: any)=>{
      
        if(resp.datos.length > 0){

          this.modal = new bootstrap.Modal(this.modalConfirm.nativeElement);
          this.modal.show();
          this.msgModalBody = resp.datos[0];
        }

      },
      error: (error)=>{
        this.isloading  = false;
        this.disabled   = true;
        console.log('error',error);
      }
    });
  }

  procesarPedido(){
    this.modal.hide();
    this.crearPedidoSiisa();
  }

  cancelarPedido(){
    this.isloading  = false;
    this.disabled   = true;
  }


  getUsuario(){
    this.datosUsuario = JSON.parse(localStorage.getItem('authToken')!);
    this.buyerEmail = this.datosUsuario.data.usuario_email;
  }

  /******* OBTENEMOS LOS PRODUCTOS DEL CARRITO ***************/
  getItemsCarrito(){
    this.carritoDetalle = this.detallePago();
  } 

  /*********** DETALLE PEDIDO SIISA ****************/
  detallePago(){
    if(this.listaRepuestos){
      return this.listaRepuestos.map((i:any)=>{
        return {
          referencia: i.id,
          cantidad: i.cantidad,
          precio: parseInt(i.precio),
          flete: 1000
        }
      });    
    }
    return [];
  }


  ngOnDestroy(): void {
    if(this.crearPedidoSiisa$){
      this.crearPedidoSiisa$.unsubscribe();
    }
    if(this.crearPedidoSiisa$){
      this.crearPedidoSiisa$.unsubscribe();
    }
    if(this.getPedidosCliente$){
      this.crearPedidoSiisa$.unsubscribe();
    }
  }

}