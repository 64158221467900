import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';



// Rutas
import { APP_ROUTING } from './app.routes'




//Servicios


//Componentes
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { RepuestosComponent } from './components/repuestos/repuestos.component';
import { RepuestoComponent } from './components/repuesto/repuesto.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

import { ButtonModule } from 'primeng/button';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { CheckoutComponent } from './compra/pages/checkout/checkout.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { RegistarseComponent } from './auth/pages/registarse/registarse.component';
import { RecodarPasswordComponent } from './auth/pages/recodar-password/recodar-password.component';
import { ResetPasswordComponent } from './auth/pages/reset-password/reset-password.component';
import { DatosdeenvioComponent } from './compra/pages/datosdeenvio/datosdeenvio.component';
import { FormClienteComponent } from './components/form-cliente/form-cliente.component';
import { RepuestoCardComponent } from './components/repuesto-card/repuesto-card.component';
import { SecureUrlPipe } from './pipes/secure-url.pipe';
import { LoadingComponent } from './shared/loading/loading.component';
import { ProgressCompraComponent } from './shared/progress-compra/progress-compra.component';
import { FormAgendamientoComponent } from './components/form-agendamiento/form-agendamiento.component';
import { ConfirmarComponent } from './compra/pages/confirmar/confirmar.component';
import { PayuComponent } from './compra/pages/payu/payu.component';
import { TablaResumenIvaComponent } from './shared/tabla-resumen-iva/tabla-resumen-iva.component';
import { TablaPedidoComponent } from './shared/tabla-pedido/tabla-pedido.component';
import { ConfirmacionPagoComponent } from './compra/pages/confirmacion-pago/confirmacion-pago.component';
import { InitcapPipe } from './pipes/initcap.pipe';
import { TabsFiltroComponent } from './components/tabs-filtro/tabs-filtro.component';

/*import {LineaService} from './services/linea.service'*/

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    RepuestosComponent,
    RepuestoComponent,
    SidebarComponent,
    SpinnerComponent,
    CheckoutComponent,
    RegistarseComponent,
    RecodarPasswordComponent,
    ResetPasswordComponent,
    DatosdeenvioComponent,
    FormClienteComponent,
    RepuestoCardComponent,
    SecureUrlPipe,
    LoadingComponent,
    ProgressCompraComponent,
    FormAgendamientoComponent,
    ConfirmarComponent,
    PayuComponent,
    TablaResumenIvaComponent,
    TablaPedidoComponent,
    ConfirmacionPagoComponent,
    InitcapPipe,
    TabsFiltroComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    APP_ROUTING,
    ButtonModule,
    FormsModule
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }      
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
