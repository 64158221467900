import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { AuthService } from 'src/app/services/auth.services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { msg } from 'src/app/interfaces/mensajes.interface';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.css']
})


export class ConfirmarComponent implements OnInit, OnDestroy  {

  carritoItems$ = new Subscription(); 
  getProductosBD$ = new Subscription(); 
  listaRepuestos:any = [];
  showLoading = false;
  msg_entrega = false;
  frase = msg.FRASE_TIENDA;
  title_tiempo_entrega = msg.TITLE_TIEMPO_ENTREGA;
  msg_tiempo_entrega = msg.MSG_TIEMPO_ENTREGA;
  terminos = false;
  deshabilitar = true;
  urlTYC = environment.TYC;
  usuario: any;
  usuario_cliente = '';
  
  @ViewChild('chkConfirmar') chkConfirmar!: ElementRef 

  iva = msg.IVA;
  ivaplus = msg.IVAPLUS;

  constructor(private location: Location, private authSrv: AuthService, private router: Router, private carritoSrv: CarrocompraService) { }
  
  logged = this.authSrv.islogIn$;
 
  ngOnInit(): void {
    this.isLogin();
    this.listarRepuestos();
    this.carritoItems$ = this.carritoSrv.itemsCarrito$.subscribe((items)=>{
      this.listaRepuestos = items;  
    });
  }

  atras() {
    this.location.back();
  }

  isLogin(){
    const logIn = this.authSrv.isLoggedIn();
    if( !logIn ){
      this.router.navigate(['/home']);
    }
    this.usuario = this.authSrv.getUsuario();
    this.usuario_cliente = this.usuario.data.usuario_cliente; 
  }

  listarRepuestos(){
    if(this.usuario == null){
      this.listaRepuestosLocalStorage();
    }else{
      this.listaRepuestosBD(this.usuario);
    }
  }


  listaRepuestosLocalStorage(){
    this.carritoSrv.setItemsCarritoBD(this.carritoSrv.getRepuestos())
  }


  listaRepuestosBD(usuario: any){
    const payload = usuario.data.usuario_cliente;
    this.showLoading = true;

    this.getProductosBD$ = this.carritoSrv.getProductosBD(payload).subscribe((resp: any)=>{
      this.showLoading = false;
      this.listaRepuestos = resp.data;
      
    }, (error: HttpErrorResponse)=>{
      this.showLoading = false;
      console.log('error',error);
    });
  }

  okTerminos(event: Event ){
    const element = event.target as HTMLInputElement;

    if(element.checked){
      this.msg_entrega = true;
      this.deshabilitar = false;
    }else{
      this.terminos = false;
      this.deshabilitar = true;
      this.msg_entrega = false;
    }
  }

  
  getIvaSinSeparadorMiles(){
    return this.getIVA().toFixed(2);
  } 

  getTotalPrice(): number {
    return this.listaRepuestos.reduce((sum: any, item: any) => sum + parseInt(item.precio) * item.cantidad, 0);
  }
  
  getIVA(): number {
    return this.getImporte()*this.iva;
  }
  
  getImporte(): number {
    return this.getTotalPrice()/this.ivaplus;
  }


  ngOnDestroy(): void {
    
    if( this.getProductosBD$ ){
      this.getProductosBD$.unsubscribe(); 
    }
  }

}
