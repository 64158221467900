import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { AuthService } from 'src/app/services/auth.services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { BusquedaService } from 'src/app/services/busqueda.service';
import { UtilService } from 'src/app/services/util.service';
import { msg } from 'src/app/interfaces/mensajes.interface';




@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.css']
})
export class ConfirmarComponent implements OnInit, OnDestroy  {



  carritoItems$ = new Subscription(); 
  getProductosBD$ = new Subscription(); 
  listaRepuestos:any = [];
  carritoDetalle = [];
  showLoading = false;
  msg_entrega = false;
  frase = msg.FRASE_TIENDA;
  title_tiempo_entrega = msg.TITLE_TIEMPO_ENTREGA;
  msg_tiempo_entrega = msg.MSG_TIEMPO_ENTREGA;
  terminos = false;
  deshabilitar = true;
  msgProductoNoDisponible = '';
  urlTYC = environment.TYC;
  usuario: any;
  usuario_cliente = '';
  referencia_producto = '';
  
  @ViewChild('chkConfirmar') chkConfirmar!: ElementRef 

  
  iva = msg.IVA;
  ivaplus = msg.IVAPLUS;

  //PAYU
  apiKey = environment.APIKEY;
  merchantId = environment.MERCHANTID;
  currency = environment.CURRENCY;
  signature = '';
  referencePago = '';

  crearPedidoSiisa$ = new Subscription();


  constructor(private location: Location, private authSrv: AuthService, private router: Router, private carritoSrv: CarrocompraService, 
             private busquedaSrv: BusquedaService, private utilSrv: UtilService) { }
  
  logged = this.authSrv.islogIn$;

  
  ngOnInit(): void {
    this.isLogin();
    this.listarRepuestos();
    this.carritoItems$ = this.carritoSrv.itemsCarrito$.subscribe((items)=>{
      this.listaRepuestos = items;  
    });
  }

  atras() {
    this.location.back();
  }

  isLogin(){
    const logIn = this.authSrv.isLoggedIn();
    if( !logIn ){
      this.router.navigate(['/home']);
    }
    this.usuario = this.authSrv.getUsuario();
    this.usuario_cliente = this.usuario.data.usuario_cliente; 
  }

  listarRepuestos(){
    if(this.usuario == null){
      this.listaRepuestosLocalStorage();
    }else{
      this.listaRepuestosBD(this.usuario);
    }
  }


  listaRepuestosLocalStorage(){
    this.carritoSrv.setItemsCarritoBD(this.carritoSrv.getRepuestos())
  }

  listaRepuestosBD(usuario: any){
    const payload = usuario.data.usuario_cliente;
    this.showLoading = true;

    this.getProductosBD$ = this.carritoSrv.getProductosBD(payload).subscribe((resp: any)=>{
      this.showLoading = false;
      this.listaRepuestos = resp.data;
      
    }, (error: HttpErrorResponse)=>{
      this.showLoading = false;
      console.log('error',error);
    });
  }

  async okTerminos(event: Event ){
    const element = event.target as HTMLInputElement;

    if(element.checked){
      this.msg_entrega = true;
      this.showLoading = true;  
      const msgCarrito = await this.validarDisponibilidad();
      this.showLoading = false;
      
      if(msgCarrito){
        this.utilSrv.showAlert(msgCarrito,'Advertencia!','warning');
      }else{
        if(this.signature){
          this.deshabilitar = false;
          return;
        }
        this.crearPedidoSiisa();
      } 

    }else{
      this.carritoSrv.removeLocalStore('carritoTmp');
      this.terminos = false;
      this.deshabilitar = true;
      this.msg_entrega = false;
    }
  }

  
  async validarDisponibilidad(){
    let msg:any = ''; 

    if(this.listaRepuestos.length == 0){
      msg = 'No hay items en el carrito de compras';
    }
    
    for (let x in this.listaRepuestos){
      try {
        let resp:any =  await this.busquedaSrv.getDisponibilidad(this.listaRepuestos[x].id);
        let msgResp = this.compararCantidad( this.listaRepuestos[x],resp.datos);
        
        if(msg){
          msg = `${msg} ${msgResp}`;
        }else{
          msg = msgResp;
        } 

      } catch (error) {
        console.log('error',error);
        return 'Error al consultar la disponibilidad';
      }
    }

    return msg;
  }

  getIvaSinSeparadorMiles(){
    return this.getIVA().toFixed(2);
  } 


  compararCantidad(itemCarrito: any, itemBodega:any){
    let msg = '';
    if(itemCarrito.cantidad > parseInt(itemBodega.refebode_disponible) ){
      msg = `No hay suficiente cantidad en bodega del repuesto ${itemBodega.referencia_nombre} `;
    }
    return msg;
  }

  generarReferencia(){

    if(this.signature){
      this.deshabilitar = false;
      return;
    }

    console.log('referencePago',this.referencePago);
    const payload = {
      apiKey      : this.apiKey,
      merchantId  : this.merchantId,
      reference   : this.referencePago,
      amount      : this.getTotalPrice(),
      currency    : this.currency
    }

    this.carritoSrv.getSignaturePayu(payload).subscribe({
      next: (resp: any)=>{
        if( resp.state ){
          this.signature = resp.data;
          this.deshabilitar = false;
        }
      },
      error: (error)=>{
        console.log('error',error);
      }
    });
  }

  
  /************ CREAMOS EL PEDIDO EN SIISA ******************/
  crearPedidoSiisa(){

    this.getItemsCarrito();
    
    const items: any[] = this.carritoDetalle.map((i:any)=>{
      return i.referencia;
    });

    if ( items.length > 0 ){
      this.referencia_producto = items.join();
    }
    
    //SI YA EXISTE PEDIDO NO LO VUELVE A CREAR
    if(this.signature){
      return;
    }
    
    const payload = {
      encabezado:[
        {
          empresa: "SSY",
          ClienteCodigo: this.usuario.data.cliente.clierepu_codigo,
          numeropedido: 0,
          bodega: "02",
          tipopedido:"01",
          fpedido: new Date(),
          destino:"00"
        }
      ],
      observaciones: "",
      detalle: this.carritoDetalle
    }

    this.showLoading = true;  

    this.crearPedidoSiisa$ =this.carritoSrv.crearPedidoSiisa(payload).subscribe({
      next: (resp)=>{
        this.showLoading = false;
        console.log('resp-crearPedidoSiisa',resp);
        if(resp.datos[0].estadoproceso == "ROLLBACK"){
          this.utilSrv.showAlert('No se pudo crear el pedido', 'Advertencia!', 'warning');
        }else{
          const datoPedidoPrevio = '00';
          this.referencePago = '';
          this.referencePago = `${resp.datos[0].detalle[0].detamovi_bodega}${datoPedidoPrevio}${resp.datos[0].detalle[0].detamovi_recoleccion}${resp.datos[0].detalle[0].detamovi_empresa}`;
          this.generarReferencia();
        }

      },
      error:(error:HttpErrorResponse)=>{
        this.showLoading = false;
        const payloadAPI = {
          method: "crearPedidoSiisa" ,
          request_payload: JSON.stringify(payload),
          error_message: JSON.stringify(error) ,
          client_data: localStorage.getItem('authToken') 
        }
        
        this.utilSrv.crearErrorLog(payloadAPI).subscribe({
          next: (resp:any)=>{
            if(!resp.state){
              console.log('error',error);
            }
          },
          error: (error) =>{
            console.log('error',error);
          }
        });
      }
    });
  }


   /******* OBTENEMOS LOS PRODUCTOS DEL CARRITO ***************/
  getItemsCarrito(){
    this.carritoDetalle = this.detallePago();
  } 

  
  detallePago(){
    if(this.listaRepuestos){
      return this.listaRepuestos.map((i:any)=>{
        return {
          referencia: i.id,
          cantidad: i.cantidad,
          precio: parseInt(i.precio),
          flete: 1000
        }
      });    
    }
  }

  getTotalPrice(): number {
    return this.listaRepuestos.reduce((sum: any, item: any) => sum + parseInt(item.precio) * item.cantidad, 0);
  }
  
  getIVA(): number {
    return this.getImporte()*this.iva;
  }
  
  getImporte(): number {
    return this.getTotalPrice()/this.ivaplus;
  }


  ngOnDestroy(): void {
    
    if( this.crearPedidoSiisa$ ){
      this.crearPedidoSiisa$.unsubscribe(); 
    }
  }

}
