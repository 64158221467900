import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IResponsePayu } from 'src/app/interfaces/payu.interface';
import { AuthService } from 'src/app/services/auth.services';
import { CarrocompraService } from 'src/app/services/carrocompra.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-confirmacion-pago',
  templateUrl: './confirmacion-pago.component.html',
  styleUrls: ['./confirmacion-pago.component.css']
})
export class ConfirmacionPagoComponent implements OnInit, OnDestroy {

  msg='';
  usuario:any;
  carrito:any = [];
  updateEstadoCarrito$  = new Subscription();
  crearLogSiisaPasarela$  = new Subscription();
  
  constructor(private activatedRoute: ActivatedRoute, private carrocompraSrv:CarrocompraService, private authSrv: AuthService, private utilSrv: UtilService, private router: Router){ }
   
  ngOnInit(): void {
    this.getDatosUsuario();
    this.confirmacion();
  } 


  confirmacion(){
    this.activatedRoute.queryParams.subscribe((param:any)=>{
      if(param.polTransactionState == 4 || param.polTransactionState == 7){
        this.msg = 'Su pedido fue realizado con Exito';
        this.carrocompraSrv.incrementCantidad(0);
        //this.updateEstadoCarrito(param);
      }else{
        this.msg = 'Se presentaron problemas al realizar el pedido';
        this.utilSrv.showAlert('Se presentaron problemas al realizar tu pedido','Advertencia!','warning');
        this.router.navigate(['/confirmar']);
      }
    });
  }

  
  /************ ACTUALIZAMOS EL ESTADO DEL CARRITO EN EL BACKEND ******************/
  updateEstadoCarrito(respPayu: any){

    const payload = {
      id_cliente: this.usuario.data.cliente.cliente_codigo, 
      carcomp_estado: 'IN', 
      carcomp_estado_transaccion: respPayu.lapTransactionState,
      carcomp_refrerencia_pago_pasarela: respPayu.reference_pol,
      carcomp_obj_transaccion: JSON.stringify(respPayu)
    } 

    this.updateEstadoCarrito$ = this.carrocompraSrv.updateEstadoCarrito(payload).subscribe({
      next: (resp:any)=>{
        console.log('resp',resp);
        if(resp.state){
          this.carrocompraSrv.incrementCantidad(0);
          this.utilSrv.showAlert('Su pedido fue exitoso', 'Exito!', 'success');
          this.router.navigate(['/home']);
          this.crearLogTransaccionSiisa(respPayu);

        }else{
          this.utilSrv.showAlert('No se pudo al actualizar pedido', 'Advertencia!','warning');
          this.router.navigate(['/checkout']);
        }
      },
      error: (error)=>{
        console.log('error',error);
        this.utilSrv.showAlert('Se presentaron problemas al actualizar pedido', 'Error!','error');
        this.router.navigate(['/checkout']);
      }
    });
  }


  crearLogTransaccionSiisa(respPayu: IResponsePayu){

    const payload = {
      merchantId                  : respPayu.merchantId,
      transactionState            : respPayu.transactionState,
      risk                        : respPayu.risk,
      polResponseCode             : respPayu.polResponseCode,
      referenceCode               : respPayu.referenceCode,
      reference_pol               : respPayu.reference_pol,
      signature                   : respPayu.signature,
      extra1                      : respPayu.extra1,
      extra2                      : respPayu.extra2,
      polPaymentMethod            : respPayu.polPaymentMethod,
      polPaymentMethodType        : respPayu.polPaymentMethodType,
      installmentsNumber          : respPayu.installmentsNumber,
      TX_VALUE                    : respPayu.TX_VALUE,
      TX_TAX                      : respPayu.TX_TAX,
      paonconf_valoradicional     : 0,
      currency                    : respPayu.currency,
      processingDate              : respPayu.processingDate,
      authorizationCode           : respPayu.authorizationCode,
      cus                         : respPayu.cus,
      pseBank                     : respPayu.pseBank,
      buyerEmail                  : respPayu.buyerEmail,
      TX_ADMINISTRATIVE_FEE       : respPayu.TX_ADMINISTRATIVE_FEE,
      paonconf_ip                 : "",
      paonconf_pagorecurrente     : "",
      paonconf_numerovisible      : "",
      paonconf_tarjetahabiente    : "",
      lapPaymentMethod            : respPayu.lapPaymentMethod,
      paonconf_estado             : "AC",
    }

    this.crearLogSiisaPasarela$ = this.carrocompraSrv.crearLogSiisaPasarela(payload).subscribe({
      next:(resp)=>{
        console.log('resp-crearLogSiisaPasarela',resp);
      },
      error: (error)=>{
       
        const payloadAPI = {
          method: "crearLogSiisaPasarela" ,
          request_payload: JSON.stringify(payload),
          error_message: JSON.stringify(error) ,
          client_data: localStorage.getItem('authToken') 
        }
        
        this.utilSrv.crearErrorLog(payloadAPI).subscribe({
          next: (resp:any)=>{
            if(!resp.state){
              console.log('error',error);
            }
          },
          error: (error) =>{
            console.log('error',error);
          }
        });
      }
    });

  }

   /************ OBTENEMOS LOS DATOS DEL USUARIO *************/
  getDatosUsuario(){
    this.usuario = this.authSrv.getUsuario();
  }
  
  /************ DESTRUIMOS LAS SUBSCRIPCIONES ****************/
  ngOnDestroy(): void {
    if( this.updateEstadoCarrito$ ){
      this.updateEstadoCarrito$.unsubscribe(); 
    }
    
    if( this.crearLogSiisaPasarela$ ){
      this.updateEstadoCarrito$.unsubscribe(); 
    }
  }

  
}
