import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FiltrosService {
  private key = environment.SIISA_KEY;
  private apiUrl = environment.API_SIISA_TV; 
  public palabra : any[] = []; 
  publicseleccionadoSubgrupo: any[] = [];
  public datosprecio : any[] = []; 
  public datosimagenes : any[] = []; 
  seleccionadoSubgrupo: any;
  producto: any[] = [];
  constructor(private httpClient: HttpClient,) { }
  
 

  getSubgrupo(seleccionado:string){
    const data= {
    p_access:this.key,
    action:"getReferencia_Catalogo",
    tipo: 1,
    marca: "SSY",
    linea: 100102,
    grupo: seleccionado

    }
    const params = new HttpParams ({ fromObject:data});
    return this.httpClient.get <any> (this.apiUrl, {params})
  }

  getSubgrupoS(seleccionado: any){
    const data= {
     p_access:this.key,
     action:"getReferencia_Catalogo",
     tipo: 1,
     marca: "SSY",
     linea: seleccionado.linea,
     grupo: seleccionado.grupo,
    } 
    const params = new HttpParams ({ fromObject:data});
    return this.httpClient.get <any> (this.apiUrl, {params})
  }
 
  getProductos(seleccionado: any){
    const data= {
      p_access:this.key,
      action:"getReferencia_Catalogo",
      tipo: 1,
      marca: "SSY",
      linea: seleccionado.linea,
      grupo: seleccionado.grupo,
      subgrupo: seleccionado.subgrupo
    }
    const params = new HttpParams ({ fromObject:data});
    return this.httpClient.get <any> (this.apiUrl, {params})
  }

  getPalabra(palabra:string, linea:string){
    const data={
      p_access:this.key,
      action:"_getLista_producto",
      p_marca:'SSY',
      linea: linea,
      p_referencia_d: palabra
    }
    const params = new HttpParams ({ fromObject:data});
    return this.httpClient.get <any> (this.apiUrl, {params})
  }

  setSubgrupoSeleccionado(producto:string){
    this.producto.push({producto})
  }

}
