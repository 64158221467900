import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit,OnDestroy {

  resetPasswordForm: FormGroup;
  isloading = false;
  token?: string; 
  resetPassword$: Subscription = new Subscription();
  validationMessages!: { [key: string]: { [key: string]: string } };

  constructor(private fb: FormBuilder, private utilService: UtilService, private usuarioService: UsuarioService, private activatedRoute: ActivatedRoute, private router: Router){
    
    this.resetPasswordForm = this.fb.group({
      password: ['',[Validators.required, Validators.minLength(6) ]],
      confirmPassword: ['',[Validators.required, Validators.minLength(6) ]]
    },  {validators: this.comparaPassword } );

    /************ MENSAJES DE VALIDACION ******************/
    this.validationMessages = {
      password: {
        required: 'Contraseña requerida',
        minlength: 'la contraseña debe contener al menos 6 caracteres'
      },
      confirmPassword: {
        required: 'Confirmación requerida',
        minlength: 'La confirmación de la contraseña debe contener al menos 6 caracteres',
        mismatch: 'Las contraseñas no coinciden'
      }

    };

  }

 
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((resp:any)=>{
      this.token = resp.token;
    })
  }

  onSubmit(){

    const pass = this.resetPasswordForm.get('password')?.value;

    if( this.token == "" || pass == "" ){
      this.utilService.showAlert('El token o password son requeridos', 'Advertencia!','warning');
      return;
    }

    const payload = {
      token: this.token,
      password:{
        password: pass
      }
    }

    this.isloading = true;  

    this.resetPassword$ =this.usuarioService.resetPassword(payload).subscribe((resp:any)=>{
      this.isloading = false;
      if(resp.state){
        this.router.navigate(['/home']);
        this.utilService.showAlert('Su contraseña se generó exitosamente', 'Exito!', 'success');
      }
    }, (error:HttpErrorResponse)=>{
      console.log('error',error);
      this.isloading = false;
      if( typeof(error.error) == 'object' ){  
        this.utilService.showAlert(error.error.message, 'Advertencia!', 'warning');
      }else{
        this.utilService.showAlert(error.message,'Error!','error');
      }
    });
  }


  comparaPassword(form: AbstractControl){
  
    if(form.get('password')!.value === form.get('confirmPassword')!.value){
      return null;
    }else{
      form.get('confirmPassword')?.setErrors({ 'mismatch': true })
      return { 'mismatch': true };
    }
  }

  
  getValidationMessage(field: string): string {
    const control = this.resetPasswordForm.get(field);
    if (control && control.errors) {
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          return this.validationMessages[field][key];
        }
      }
    }
    return '';
  }


  ngOnDestroy(): void {
    if( this.resetPassword$ ){
      this.resetPassword$.unsubscribe();
    }
  }

}