import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LineaService } from 'src/app/services/linea.service';


@Component({
  selector: 'app-tabs-filtro',
  templateUrl: './tabs-filtro.component.html',
  styleUrls: ['./tabs-filtro.component.css'],
})
export class TabsFiltroComponent implements OnInit, OnDestroy {

  @Input() filtros: any[] = [];
  @Output() consultar = new EventEmitter<any>();

  getLineas$: Subscription      = new Subscription(); 
  getAnosModelos$: Subscription = new Subscription(); 
  getFamilias$: Subscription    = new Subscription(); 
  getcajaCambios$: Subscription = new Subscription(); 
  getCombustibles$: Subscription = new Subscription(); 

  /**CLASES*/
  modelo_invalid  = '';
  familia_invalid = '';
  caja_invalid    = '';
  linea_invalid   = '';
  invalid         = '';
  combustible_invalid = '';

  tabNumber:number = 1;
  modelos:  any[] = [];
  familias: any[] = [];
  lineas: any[]   = [];
  cajaCambios:  any[] = [];
  combustibles: any[] = [];

  placa: any  = { label: 'placa', valor: '' }
  vin: any    = { label: 'vin', valor: '' }
  ref: any    = { label: 'referencia', valor: '' }
  linea: any  = [
    { label: 'linea', valor: '' }
  ];

  cmbModelo = '' ;
  cmbfamilia = '';
  cmbcaja = '';
  cmbcombustible = ''
  cmblinea = ''
  loading = false;

  constructor(private lineaSrv:LineaService){}

  ngOnInit(): void {
    this.lineaSrv.getModels().subscribe(resp=>{
      this.modelos = resp;
    })
    this.obtenerAnosModelo();
  }
  
 
  activarTab(tab: number){
    this.tabNumber = tab;
    this.invalid = "";
  }


  handlerConsultar(){
    if(this.tabNumber == 1){
      if(this.placa.valor == ""){
        this.invalid = "form-control is-invalid";
      }else{
        this.invalid = "";
        this.placa.consulta = this.tabNumber;
        this.consultar.emit(this.placa);
      }

    }else if(this.tabNumber == 2){
      if(this.vin.valor == ""){
        this.invalid = "form-control is-invalid";
      }else{
        this.invalid = "";
        this.vin.consulta = this.tabNumber;
        this.consultar.emit(this.vin);
      }

    }else if(this.tabNumber == 3){
      if(this.ref.valor == ""){
        this.invalid = "form-control is-invalid";
      }else{
        this.invalid = "";
        this.ref.consulta = this.tabNumber;
        this.consultar.emit(this.ref);
      }

    }else if(this.tabNumber == 4){
      const validar = this.validaLinea();
      if(validar){
        const linea = {
          modelo: this.cmbModelo,
          familia: this.cmbfamilia,
          caja: this.cmbcaja,
          combustible: this.cmbcombustible,
          linea: this.cmblinea,
          consulta : this.tabNumber
        }
        this.consultar.emit(linea);
      } 

    }
  }

  obtenerAnosModelo(){

    if(this.modelos.length > 0){
      return;
    }

    this.getAnosModelos$ = this.lineaSrv.getAnosModelo().subscribe({
      next: (resp: any)=>{
        if(resp.datos.retorno.length > 0){
          this.modelos = resp.datos.retorno;
          this.lineaSrv.setModelos(resp.datos.retorno);
        }
      }, 
      error: (error: any)=>{
        console.log('error',error);
      }
    });
  }

  obtenerFamilia(event: Event ) {
    const e = event.target as HTMLInputElement;
    this.cmbModelo= e.value;
    this.modelo_invalid = "";
    this.cmbfamilia     = "";
    this.cmbcaja        = "";
    this.cmblinea       = "";
    this.cmbcombustible = "";

    this.familias     = [];
    this.cajaCambios  = [];
    this.combustibles = [];
    this.lineas       = [];
    this.loading      = true;

    const payload = {
      modelo: this.cmbModelo
    }

    this.getFamilias$ = this.lineaSrv.getFamilia(payload).subscribe({
      next: (resp: any)=>{
        this.loading = false;
        if(resp.datos.retorno.length > 0){
          this.familias = resp.datos.retorno;
        }
      }, 
      error: (error:any)=>{
        this.loading = false;
        console.log('error',error);
      }
    });
  }

  obtenerCajaCambios(event: Event){
    const e = event.target as HTMLInputElement;  
    this.cmbfamilia = e.value;

    this.familia_invalid = "";
    this.cajaCambios  = [];
    this.combustibles = [];
    this.lineas       = [];
    this.cmbcaja      = '';
    this.cmblinea     = '';
    this.cmbcombustible = '';
    
    if( !(this.cmbfamilia).trim() ){
      return;
    }

    const payload = {
      modelo  : this.cmbModelo, 
      familia : this.cmbfamilia
    }

    this.loading = true;
    this.getcajaCambios$ = this.lineaSrv.getcajaCambios(payload).subscribe({
      next: (resp: any)=>{
        this.loading = false;
        if(resp.datos.retorno.length > 0){
          this.cajaCambios = resp.datos.retorno;
        }
      },
      error: (error:any)=>{
        this.loading = false;
        console.log('error',error);
      }
    });
  }

  obtenerCombustible(event: Event){
    const e = event.target as HTMLInputElement;
    this.cmbcaja = e.value;
    this.caja_invalid = "";
    this.combustibles = [];
    this.lineas       = [];
    this.cmblinea     = '';
    this.cmbcombustible = '';

    const payload = {
      modelo  : this.cmbModelo, 
      familia : this.cmbfamilia, 
      caja    : this.cmbcaja  
    }
    
    this.loading = true;
    this.getCombustibles$ = this.lineaSrv.getCombustible(payload).subscribe({
      next: (resp: any)=>{
        this.loading = false;
        if(resp.datos.retorno.length > 0){
          this.combustibles = resp.datos.retorno;
        }
      },
      error: (error:any)=>{
        this.loading = false;
        console.log('error',error);
      }
    });
  }

  obtenerLinea(event: Event){

    const e = event.target as HTMLInputElement;
    this.cmbcombustible = e.value;
    this.combustible_invalid = "";
    
    const payload = {
      modelo:   this.cmbModelo,
      familia:  this.cmbfamilia,
      caja:     this.cmbcaja,
      motor:    this.cmbcombustible
    }

    this.loading = true;
    this.getLineas$ = this.lineaSrv.getLinea(payload).subscribe({
      next: (resp: any)=>{
        this.loading = false;
        if(resp.datos.retorno.length > 0){
          this.lineas = resp.datos.retorno;
        }
      },
      error: (error:any)=>{
        this.loading = false;
        console.log('error',error);
      }
    });
  }

  selecionarLinea(event: Event){
    const e = event.target as HTMLInputElement;
    this.cmblinea = e.value;
    this.linea_invalid = "";
  }


  validaLinea(){
    let resultado = true;
    if( this.cmbModelo == ""){
      this.modelo_invalid = "form-control is-invalid";
      resultado = false;
    }else{
      this.modelo_invalid = "";
      resultado = true;
    } 
    
    if (this.cmbfamilia == ""){
      this.familia_invalid = "form-control is-invalid";
       resultado = false;
    }else{
      this.familia_invalid = "";
      resultado = true;
    } 
    
    if (this.cmbcaja == ""){
      this.caja_invalid = "form-control is-invalid";
       resultado = false;
    }else{
      this.caja_invalid = "";
      resultado = true;
    } 
    
    if(this.cmbcombustible == ""){
      this.combustible_invalid = "form-control is-invalid";
       resultado = false;
    }else{
      this.combustible_invalid = "";
      resultado = true;
    } 
    
    if (this.cmblinea == ""  ){
      this.linea_invalid = "form-control is-invalid";
      resultado = false;
    }else{
      this.linea_invalid = "";
      resultado = true;
    } 

    return resultado;
  }

  ngOnDestroy(): void {
   if ( this.getLineas$ ){
    this.getLineas$.unsubscribe();
   }
   
   if ( this.getAnosModelos$ ){
    this.getAnosModelos$.unsubscribe();
   }

   if(this.getFamilias$){
    this.getFamilias$.unsubscribe();
   }
   
   if(this.getcajaCambios$){
    this.getcajaCambios$.unsubscribe();
   }
   
   if(this.getCombustibles$){
    this.getCombustibles$.unsubscribe();
   }
  }

}
