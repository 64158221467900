<div id="capturar"  class="flex-column gap-2 align-items-center">

  <div class="seccion-busqueda">
    <div class="d-flex flex-column justify-content-center align-items-center flex-wrap w-75">
      <app-tabs-filtro
        [filtros]="filtros"
        (consultar)="capturarDatos($event)"
      /> 
    </div>
  </div>
  
  <div class="mt-5">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <img class="i-proceso" src="../../../assets/img/frame94.png" style="width: 1024px; height: auto;"  alt="garantia">
      <img class="i-proceso-mobile" src="../../../assets/img/mobile.png"  style="width: 335px; height: auto;"  alt="garantia">
      <a class="boton_sc" [href]="coordinadora | secureUrl " target="_blank">Sigue tus pedidos con Coordinadora </a> 
      <br>
      <a [href]="whatsapp | secureUrl " target="_blank"  class="btn-wapp"></a>
    </div>
  </div>
  
</div>