import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { IResponseClienteSiisa } from "../interfaces/auth.interfece";


@Injectable({
    providedIn: 'root'
})

export class AuthService{

  private islogIn = new BehaviorSubject(false);
  islogIn$ = this.islogIn.asObservable();
  private key = environment.SIISA_KEY;

  API_SIISA_CL = environment.API_SIISA_CL;

  constructor(private http: HttpClient){}

  isLoggedIn(): boolean {
    const token = localStorage.getItem('authToken');
    return !!token; // Retorna true si el token existe, false si no
  }

  login(login: boolean){
    this.islogIn.next(login);
  }

  setAuth( auth: any ){
    localStorage.setItem('authToken', JSON.stringify(auth) );
  }

  removeKeylocalStorage(item: string){
    const existeItem =  localStorage.getItem(item);
    if( !!existeItem ){
        localStorage.removeItem(item);
    }
  }

  getUsuario(){
    const usuario = localStorage.getItem('authToken'); 

    if( !!usuario ){
      return JSON.parse(usuario);
    }
    return null;
  } 

  /*********** CLIENTES SIISA ****************** */  
  getClienteSiisa(payload: any){
    
    const URL = this.API_SIISA_CL;

    const params = new HttpParams()
      .set('p_access', this.key)
      .set('action', '_getvalidaclierepu')
      .set('nit', payload.nit)
      .set('tipodocumento', payload.tipodocumento);

    return  this.http.get<IResponseClienteSiisa>(URL, {params});
  }

  crearClienteSiisa(payload: any){
    
    const URL = this.API_SIISA_CL;

    const params = new HttpParams()
      .set('p_access', this.key)
      .set('action', '_insertCliente')
      .set('tipocliente', payload.tipocliente)
      .set('tipoidentificacion', payload.tipoidentificacion)
      .set('nit', payload.nit)
      .set('tipopersona', payload.tipopersona)
      .set('apellido1', payload.apellido1)
      .set('apellido2', payload.apellido2)
      .set('nombre1', payload.nombre1)
      .set('nombre2', payload.nombre2)
      .set('departamento', payload.departamento)
      .set('ciudad', payload.ciudad)
      .set('direccion', payload.direccion)
      .set('telefono', payload.telefono)
      .set('celular', payload.celular)
      .set('email', payload.email);

    return  this.http.get<any>(URL, {params});
  }
  
  updateClienteSiisa(payload: any){
    
    const URL = this.API_SIISA_CL;

    const params = new HttpParams()
      .set('p_access', this.key)
      .set('action', '_UpdateCliente')
      .set('cliente', payload.cliente)
      .set('tipocliente', payload.tipocliente)
      .set('tipoidentificacion', payload.tipoidentificacion)
      .set('tipopersona', payload.tipopersona)
      .set('apellido1', payload.apellido1)
      .set('apellido2', payload.apellido2)
      .set('nombre1', payload.nombre1)
      .set('nombre2', payload.nombre2)
      .set('departamento', payload.departamento)
      .set('ciudad', payload.ciudad)
      .set('direccion', payload.direccion)
      .set('telefono', payload.telefono)
      .set('celular', payload.celular)
      .set('email', payload.email);
    return  this.http.get<any>(URL, {params});
  }

}