export const msg = {
    FRASE_TIENDA:'SsangYong/KGM te brinda repuestos 100% originales y de excelente calidad',
    MSG_TIEMPO_ENTREGA: 'TU PEDIDO SERÁ ENTREGADO DE ACUERDO CON LAS FECHAS ESTABLECIDAS POR LA EMPRESA TRANSPORTADORA',
    VALOR_COMPRA_MINIMA:70000,
    MSG_COMPRA_MINIMA:`La compra mínima debe ser de $ 70.000`,
    TITLE_TIEMPO_ENTREGA: 'Tiempo estimado de entrega:', 
    ENVIO_GRATIS : 'Envío gratis a cualquier lugar de Colombia.',
    IVA : 0.19,
    IVAPLUS : 1.19,
    MSG_SIN_PRODUCTOS: 'No se encontraron productos con este nombre.',
    MSG_SIN_REGISTROS: 'No se encontraron registros.',
    MSG_PLACA_INVALIDA: 'No se encontró la Placa en nuestro sistema, revísalo e inténtalo nuevamente!',
    MSG_VIN_INVALIDO: 'No se encontró el VIN en nuestro sistema, revísalo e inténtalo nuevamente!',
    MSG_REF_INVALIDA: 'No se encontró el numero de referencia en nuestro sistema, revísalo e inténtalo nuevamente!',
    DESCRIPTION_PAYU: 'Compra repuestos SsangYong / KGM',
    MSG_CARRITO_SIN_ITEMS: 'No hay items en el carrito de compras',
    MSG_REOUESTO_NO_DISPONIBLE: 'No hay suficiente cantidad en bodega del repuesto',
    MSG_ERROR_PEDIDO: 'No se pudo crear el pedido',
}