import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.services';
import { environment } from 'src/environments/environment';
import { CarrocompraService } from '../services/carrocompra.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private carrocompraService: CarrocompraService, private utilService: UtilService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const usuario = this.authService.getUsuario();
    let authToken = null;
    
    if(usuario){
      authToken = usuario.token; 
    }
    const API_BACKEND = environment.API_BACKEND;

    let authReq = request;

    if(request.url.startsWith(API_BACKEND)){

      authReq = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
           'authorization': `${authToken}`
         }    
        }); 
    }
   
    return next.handle(authReq)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('error-AuthInterceptor',error);
          if (error.status === 401) {
            this.authService.removeKeylocalStorage('authToken');
            this.authService.login(false);
            this.carrocompraService.setItemsCarritoBD([]);
            this.carrocompraService.updateCantidadItemsCarrito([]);
            this.utilService.showAlert('Sesión expirada', 'Advertencia!', 'warning');
          }
          return throwError(error);
        })
      );
  }
}
