import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initcap'
})
export class InitcapPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    
    if (!value) return '';

    // Capitaliza la primera letra de cada palabra
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

}
