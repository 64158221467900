import { Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { RepuestoComponent } from "./components/repuesto/repuesto.component";
import { RepuestosComponent } from "./components/repuestos/repuestos.component";
import { CheckoutComponent } from "./compra/pages/checkout/checkout.component";
import { ResetPasswordComponent } from "./auth/pages/reset-password/reset-password.component";
import { DatosdeenvioComponent } from "./compra/pages/datosdeenvio/datosdeenvio.component";
import { ConfirmarComponent } from "./compra/pages/confirmar/confirmar.component";
import { ConfirmacionPagoComponent } from "./compra/pages/confirmacion-pago/confirmacion-pago.component";

const APP_ROUTES: Routes =[

     {path: 'home', component: HomeComponent },
     {path: 'repuestos/:inputbusqueda', component: RepuestosComponent },
     {path: 'repuesto/:inputbusqueda', component: RepuestoComponent },
     {path: 'checkout', component: CheckoutComponent },
     {path: 'datosdeenvio', component: DatosdeenvioComponent},
     {path: 'confirmar', component: ConfirmarComponent},
     {path: 'auth/reset-password/:token', component: ResetPasswordComponent },
     {path: 'confirmar-pago', component: ConfirmacionPagoComponent },
     {path: '**', pathMatch: 'full', redirectTo: 'home' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);



