<table class="table">
    <thead>
        <tr class="table-secondary">
            <th colspan="4" class="text-center" >Resumen de IVA</th>
        </tr>
        <tr>
            <th class="text-center" >Tarifa iva</th>
            <th class="text-center" >Base importe</th>
            <th class="text-center" >IVA</th>
            <th class="text-center" >TOTAL</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style="text-align: center;">19%</td>
            <td style="text-align: center;">{{ importe  }}</td>
            <td style="text-align: center;">{{ IVA }}</td>
            <td style="text-align: center;">{{ totalPrice }}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr class="table-secondary">
            <th style="text-align: center;">Total</th>
            <th style="text-align: center;">{{ importe }}</th>
            <th style="text-align: center;">{{ IVA }}</th>
            <th style="text-align: center;">{{ totalPrice }}</th>
        </tr>
    </tfoot>
</table>
